import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '../../models/router';
import { RouterSchedule } from '../../models/router-schedule';
import { RouterSetup } from '../../models/router-setup';
import { ProductionLocation } from '../../models/production-location';
import { SnackbarService } from '../../services/snackbar.service';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { RoutersService } from '../../services/routers.service';
import { RouterSchedulesService } from '../../services/router-schedules.service';
import { RouterSetupsService } from '../../services/router-setups.service';
import { ScheduleService } from '../../services/schedule.service';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-routers',
  templateUrl: './routers.component.html',
  styleUrls: ['./routers.component.less']
})
export class RoutersComponent implements OnInit {

  loading = false;
  loadingRouterSchedules = false;
  loadingRouterSetups = false;
  routersFiltersApplied = false;
  nameFilter = new FormControl();
  ipAddressFilter = new FormControl();
  productionLocationFilter = new FormControl();
  activeRoutersFilter = false;

  routers = new MatTableDataSource<Router>();
  filteredRouters = new MatTableDataSource<Router>();
  routerSchedules = new MatTableDataSource<RouterSchedule>();
  routerSetups = new MatTableDataSource<RouterSetup>();
  productionLocations: ProductionLocation[] = [];

  firstDay = moment().weekday(0).format('MMMM D');
  displayedColumns: string[] = ['id', 'name', 'productionLocationId', 'ipAddress', 'isActive', 'actions'];
  routerSchedulesDisplayedColumns: string[] = ['id', 'routerId', 'date', 'timeStart', 'timeEnd', 'actions'];
  routerSetupsDisplayedColumns: string[] = ['id', 'routerStr', 'routerScheduleStr', 'productionLocationStr', 'colors', 'styles', 'maxPartsInBatch', 'actions'];

  routersSort: MatSort;
  routerSchedulesSort: MatSort;
  routerSetupsSort: MatSort;

  @ViewChild('routersSort', { read: MatSort }) set matSort1(ms: MatSort) {
    this.routersSort = ms;
    this.routers.sort = this.routersSort;
    this.filteredRouters.sort = this.routersSort;
  }

  @ViewChild('routerSchedulesSort', { read: MatSort }) set matSort2(ms: MatSort) {
    this.routerSchedulesSort = ms;
    this.routerSchedules.sort = this.routerSchedulesSort;
  }

  @ViewChild('routerSetupsSort', { read: MatSort }) set matSort3(ms: MatSort) {
    this.routerSetupsSort = ms;
    this.routerSetups.sort = this.routerSetupsSort;
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private snackBarService: SnackbarService,
    private productionLocationsService: ProductionLocationsService,
    private routersService: RoutersService,
    private routerSchedulesService: RouterSchedulesService,
    private routerSetupsService: RouterSetupsService,
    private scheduleService: ScheduleService,
  ) { }

  ngOnInit() {
    this.getProductionLocations();
    this.getRouters();
    this.getRouterSchedules();
    this.getRouterSetups();
  }

  applyRoutersFilters() {
    this.filteredRouters.data = this.routers.data;
    if (this.nameFilter.valid && this.nameFilter.value) {
      this.filteredRouters.data = this.filteredRouters.data.filter(c => c.name.includes(this.nameFilter.value.trim()))
    }
    if (this.ipAddressFilter.valid && this.ipAddressFilter.value) {
      this.filteredRouters.data = this.filteredRouters.data.filter(c => c.ipAddress.includes(this.ipAddressFilter.value.trim()))
    }
    if (this.productionLocationFilter.valid && this.productionLocationFilter.value) {
      this.filteredRouters.data = this.filteredRouters.data.filter(c => c.productionLocationId === this.productionLocationFilter.value);
    }
    if (this.activeRoutersFilter) {
      this.filteredRouters.data = this.filteredRouters.data.filter(c => c.isActive);
    }
    this.routersFiltersApplied = true;
  }

  refresh() {
    this.getRouterSchedules();
    this.getRouterSetups();
  }

  clearRoutersFilters() {
    this.nameFilter.setValue('');
    this.ipAddressFilter.setValue('');
    this.productionLocationFilter.setValue('');
    this.filteredRouters.data = [];
    this.activeRoutersFilter = false;
    this.routersFiltersApplied = false;
  }

  getRoutersData() {
    return this.routersFiltersApplied ? this.filteredRouters : this.routers;
  }

  getRouterSchedulesData() {
    return this.routerSchedules;
  }

  getRouterSetupsData() {
    return this.routerSetups;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  getProductionLocations() {
    this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
      this.productionLocations = productionLocations;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting production locations.');
      });
  }

  getRouters() {
    this.showLoading();
    this.routersService.getRouters().subscribe((routers) => {
      this.routers.data = routers;
      this.hideLoading();
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting routers.');
      this.hideLoading();
    });
  }

  getRouterSchedules() {
    this.loadingRouterSchedules = true;
    this.routerSchedulesService.getRouterSchedules().subscribe((routerSchedules) => {
      this.routerSchedules.data = routerSchedules;
      this.loadingRouterSchedules = false;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting router schedules.');
      this.loadingRouterSchedules = false;
    });
  }

  getRouterSetups() {
    this.loadingRouterSetups = true;
    this.routerSetupsService.getRouterSetups().subscribe((routerSetups) => {
      this.routerSetups.data = routerSetups;
      this.loadingRouterSetups = false;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting router setups.');
      this.loadingRouterSetups = false;
    });
  }

  async startJITScheduling(router: Router) {
    try {
      if (router.jitSchedulingStarted) return;
      router.jitSchedulingStarted = true;
      const result = await this.scheduleService.createProductionOrder(router.id, -1, true, false).toPromise();
      if (!result) {
        throw new Error('JIT Scheduling has failed. You must check your router configurations and try again. (EN) <br/> JIT Scheduling ha fallado. Debe verificar la configuración de su Router e intentarlo nuevamente. (ES)');
      }
      this.snackBarService.showSuccess('JIT Scheduling executed successfully. A new Production Order will be available.');
    } catch (err) {
      console.error(err);
      router.jitSchedulingStarted = false;
      this.snackBarService.showError(err.message);
    }
  }

  async duplicateRouterSetups() {
    try {
      const result = await this.routerSetupsService.duplicateRouterSetups().toPromise();
      if (result) {
        this.refresh();
        return this.snackBarService.showSuccess('Router setups were duplicated successfully.');
      }
      this.snackBarService.showError('No router setups were duplicated. Please try again or review your configurations.');
    } catch (err) {
      console.error(err);
      this.snackBarService.showError(err.message);
    }
  }

  formatTime(date: Date, time: string): string {
    const d = `${moment(date).format('MM/DD/YYYY')} ${time}`;
    return moment(d, 'MM/DD/YYYY hh:mm a').format('hh:mm a');
  }

}
