import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RouterSetup } from '../models/router-setup';

@Injectable({
  providedIn: 'root'
})
export class RouterSetupsService {


  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getRouterSetups(): Observable<RouterSetup[]> {
    const url = this.config.buildURL('routerSetups');
    return this.http.get<RouterSetup[]>(url);
  }

  getRouterSetup(id: number): Observable<RouterSetup> {
    const url = this.config.buildURL(`routerSetups/${id}`);
    return this.http.get<RouterSetup>(url);
  }

  createRouterSetup(model: RouterSetup): Observable<RouterSetup> {
    const url = this.config.buildURL(`routerSetups`);
    return this.http.post<RouterSetup>(url, model);
  }

  updateRouterSetup(model: RouterSetup): Observable<RouterSetup> {
    const url = this.config.buildURL(`routerSetups/${model.id}`);
    return this.http.put<RouterSetup>(url, model);
  }

  deleteRouterSetup(id: number) {
    const url = this.config.buildURL(`routerSetups/${id}`);
    return this.http.delete(url);
  }

  duplicateRouterSetups(): Observable<boolean> {
    const url = this.config.buildURL(`routerSetups/duplicate`);
    return this.http.post<boolean>(url, null);
  }
}
