import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { FullCalendarModule } from 'ng-fullcalendar';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AccountDetailsComponent } from './components/accounts/account-details/account-details.component';
import { AccountRolesComponent } from './components/accounts/account-roles/account-roles.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { ChangePasswordComponent } from './components/accounts/change-password/change-password.component';
import { CreateAccountComponent } from './components/accounts/create-account/create-account.component';
import { DeleteAccountComponent } from './components/accounts/delete-account/delete-account.component';
import { EditAccountComponent } from './components/accounts/edit-account/edit-account.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main/main.component';
import { MaterialModule } from './material/material.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ProductionCalendarComponent } from './components/production-calendar/production-calendar.component';
import { CreateRoleComponent } from './components/roles/create-role/create-role.component';
import { DeleteRoleComponent } from './components/roles/delete-role/delete-role.component';
import { EditRoleComponent } from './components/roles/edit-role/edit-role.component';
import { RoleDetailsComponent } from './components/roles/role-details/role-details.component';
import { RolesComponent } from './components/roles/roles.component';
import { DeleteSettingComponent } from './components/settings/delete-setting/delete-setting.component';
import { EditSettingComponent } from './components/settings/edit-setting/edit-setting.component';
import { SettingDetailsComponent } from './components/settings/setting-details/setting-details.component';
import { SettingsByGroupNameComponent } from './components/settings/settings-by-group-name/settings-by-group-name.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SnackbarMessageComponent } from './components/snackbar-message/snackbar-message.component';
import { PipesModule } from './pipes/pipes.module';
import { AppRoutingModule } from './routes/app-routing.module';
import { AuthInterceptor } from './services/auth-interceptor';
import { CustomerAuthInterceptorInterceptor } from './services/customer-auth.interceptor';
import { ProductionLocationsComponent } from './components/production-locations/production-locations.component';
// eslint-disable-next-line max-len
import { CreateProductionLocationComponent } from './components/production-locations/create-production-location/create-production-location.component';
// eslint-disable-next-line max-len
import { EditProductionLocationComponent } from './components/production-locations/edit-production-location/edit-production-location.component';
// eslint-disable-next-line max-len
import { ProductionLocationDetailsComponent } from './components/production-locations/production-location-details/production-location-details.component';
// eslint-disable-next-line max-len
import { DeleteProductionLocationComponent } from './components/production-locations/delete-production-location/delete-production-location.component';
// eslint-disable-next-line max-len
import { EditProductionCalendarComponent } from './components/production-calendar/edit-production-calendar/edit-production-calendar.component';
import { RouterCimComponent } from './components/router-cim/router-cim.component';
import { ApartmentsComponent } from './components/apartments/apartments.component';
import { EditProductionOrderComponent } from './components/production-orders/edit-production-order/edit-production-order.component';
import { ApartmentDetailsComponent } from './components/apartments/apartment-details/apartment-details.component';
import { ResetPasswordComponent } from './components/accounts/reset-password/reset-password.component';
import { ProductionLimitsComponent } from './components/production-limits/production-limits.component';
import { CreateProductionLimitComponent } from './components/production-limits/create-production-limit/create-production-limit.component';
import { EditProductionLimitComponent } from './components/production-limits/edit-production-limit/edit-production-limit.component';
import { DeleteProductionLimitComponent } from './components/production-limits/delete-production-limit/delete-production-limit.component';
// eslint-disable-next-line max-len
import { ProductionLimitDetailsComponent } from './components/production-limits/production-limit-details/production-limit-details.component';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
// eslint-disable-next-line max-len
import { ScheduleManagerComponent } from './components/schedule-manager/schedule-manager.component';
import { EditScheduledUnitComponent } from './components/schedule-manager/edit-scheduled-unit/edit-scheduled-unit.component';
import { SchedulerNotesDetailsComponent } from './components/schedule-manager/scheduler-notes-details/scheduler-notes-details.component';
// eslint-disable-next-line max-len
import { BottomSheetScheduleLegendComponent } from './components/shared/bottom-sheet-schedule-legend/bottom-sheet-schedule-legend.component';
import { ExportToProductionComponent } from './components/schedule-manager/export-to-production/export-to-production.component';
import { ResetPoNumberComponent } from './components/schedule-manager/reset-po-number/reset-po-number.component';
// eslint-disable-next-line max-len
import { EditScheduledUnitConfirmationComponent } from './components/schedule-manager/edit-scheduled-unit-confirmation/edit-scheduled-unit-confirmation.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { MiterSawComponent } from './components/miter-saw/miter-saw.component';
import { EditTrainComponent } from './components/miter-saw/edit-train/edit-train.component';
import { RdbFilesComponent } from './components/miter-saw/rdb-files/rdb-files.component';
import { GibenComponent } from './components/giben/giben.component';
import { ScanLookupsComponent } from './components/scan-lookups/scan-lookups.component';
import { PromoCodesComponent } from './components/promo-codes/promo-codes.component';
import { CreatePromoCodeComponent } from './components/promo-codes/create-promo-code/create-promo-code.component';
import { EditPromoCodeComponent } from './components/promo-codes/edit-promo-code/edit-promo-code.component';
import { DeletePromoCodeComponent } from './components/promo-codes/delete-promo-code/delete-promo-code.component';
import { PromoCodeDetailsComponent } from './components/promo-codes/promo-code-details/promo-code-details.component';
import { IMaskModule } from 'angular-imask';
import { AccountingComponent } from './components/accounting/accounting.component';
import { CreateRebateGroupComponent } from './components/accounting/create-rebate-group/create-rebate-group.component';
import { EditRebateGroupComponent } from './components/accounting/edit-rebate-group/edit-rebate-group.component';
import { DeleteRebateGroupComponent } from './components/accounting/delete-rebate-group/delete-rebate-group.component';
import { EditRebateGroupAssignmentsComponent } from './components/accounting/edit-rebate-group-assignments/edit-rebate-group-assignments.component';
import { RebateGroupAssignmentsDetailsComponent } from './components/accounting/rebate-group-assignments-details/rebate-group-assignments-details.component';
import { RebateGroupDetailsComponent } from './components/accounting/rebate-group-details/rebate-group-details.component';
import { RebateReportsComponent } from './components/accounting/rebate-reports/rebate-reports.component';
import { CommissionDashboardComponent } from './components/commissions/commission-dashboard/commission-dashboard.component';
import { ResumeCommissionsComponent } from './components/commissions/resume-commissions/resume-commissions.component';
import { ManagerCommissionsComponent } from './components/commissions/manager-commissions/manager-commissions.component';
import { SettingsCommissionsComponent } from './components/commissions/settings-commissions/settings-commissions.component';
import { CommunitiesCommissionsComponent } from './components/commissions/communities-commissions/communities-commissions.component';
import { CommunityModalCommissionsComponent } from './components/commissions/community-modal-commissions/community-modal-commissions.component';
import { SettingsCommissionModalComponent } from './components/commissions/settings-commission-modal/settings-commission-modal.component';
import { SummaryComponent } from './components/summary/summary.component';
import { ScanBarcodesComponent } from './components/scan-barcodes/scan-barcodes.component';
import { CustomerModalCommissionsComponent } from './components/commissions/customer-modal-commissions/customer-modal-commissions.component';
import { PreventRecalculateCommissionComponent } from './components/commissions/prevent-recalculate-commissions/prevent-recalculate-commission.component';
import { CommissionCustomerAccountComponent } from './components/commissions/commission-customer-account/commission-customer-account.component';
import { ReasonCodesComponent } from './components/reason-codes/reason-codes.component';
import { ListReasonCodesComponent } from './components/reason-codes/list-reason-codes/list-reason-codes.component';
import { CreateReasonCodeComponent } from './components/reason-codes/create-reason-code/create-reason-code.component';
import { EditReasonCodeComponent } from './components/reason-codes/edit-reason-code/edit-reason-code.component';
import { ListReasonCategoriesComponent } from './components/reason-codes/list-reason-categories/list-reason-categories.component';
import { CreateReasonCategoryComponent } from './components/reason-codes/create-reason-category/create-reason-category.component';
import { EditReasonCategoryComponent } from './components/reason-codes/edit-reason-category/edit-reason-category.component';
import { ReasonCodesModalComponent } from './components/reason-codes/reason-codes-modal/reason-codes-modal.component';
import { ListReasonGroupsComponent } from './components/reason-codes/list-reason-groups/list-reason-groups.component';
import { CreateReasonGroupComponent } from './components/reason-codes/create-reason-group/create-reason-group.component';
import { EditReasonGroupComponent } from './components/reason-codes/edit-reason-group/edit-reason-group.component';
import { ReasonGroupsModalComponent } from './components/reason-codes/reason-groups-modal/reason-groups-modal.component';
import { OrderNotificationComponent } from './components/order-notification/order-notification/order-notification.component';
import { CreateOrderNotificationComponent } from './components/order-notification/create-order-notification/create-order-notification.component';
import { DetailReadingNotificationComponent } from './components/order-notification/detail-reading-notification/detail-reading-notification.component';
// import { SelectAutocompleteModule } from 'ng-select-autocomplete';
import { WhatsNotComponent } from './components/whats-not/whats-not.component';
import { MatSortModule } from '@angular/material/sort';
import { AuditComponent } from './components/audit/audit.component';
import { AuditAssignmentsDetailsComponent } from './components/audit/audit-assignments-details/audit-assignments-details.component';
import { EditAuditAssignmentsComponent } from './components/audit/edit-audit-assignments/edit-audit-assignments.component';
import { OvernightDeliveryComponent } from './components/overnight-delivery/overnight-main/overnight-delivery.component';
import { OrderSearchComponent } from './components/overnight-delivery/order-search/order-search.component';
import { AddressDetailsComponent } from './components/overnight-delivery/address-details/address-details.component';
import { PackageShipmentDetailsComponent } from './components/overnight-delivery/package-shipment-details/package-shipment-details.component';
import { RatesTransitTimesComponent } from './components/overnight-delivery/rates-transit-times/rates-transit-times.component';
import { EmailSendingConfirmationComponent } from './components/overnight-delivery/rates-transit-times/email-sending-confirmation/email-sending-confirmation.component';
import { EmailUpdateModalComponent } from './components/overnight-delivery/rates-transit-times/email-sending-confirmation/email-update-modal/email-update-modal.component';

import { MultiSelectAutocompleteComponent } from './components/shared/multi-select-autocomplete/multi-select-autocomplete.component';
import { CustomerLookupComponent } from './components/customer-lookup/customer-lookup.component';
import { ProductionMoldingDetailsComponent } from './components/settings/production-molding/production-molding-details/production-molding-details.component';
import { EditProductionMoldingComponent } from './components/settings/production-molding/edit-production-molding/edit-production-molding.component';
import { DeleteProductionMoldingComponent } from './components/settings/production-molding/delete-production-molding/delete-production-molding.component';
import { CreateProductionMoldingComponent } from './components/settings/production-molding/create-production-molding/create-production-molding.component';
import { AlertOvernightProcessComponent } from './components/overnight-delivery/order-search/alert-overnight-process/alert-overnight-process.component';

import { ClUpdateUserModalComponent } from './components/customer-lookup/cl-update-user-modal/cl-update-user-modal.component';
import { ClDeleteUserModalComponent } from './components/customer-lookup/cl-delete-user-modal/cl-delete-user-modal.component';
import { ClAddAccountModalComponent } from './components/customer-lookup/cl-add-account-modal/cl-add-account-modal.component';
import { ClArchivedUnitComponent } from './components/customer-lookup/cl-archived-unit/cl-archived-unit.component';
import { UnarchiveUnitConfirmationComponent } from './components/customer-lookup/cl-archived-unit/unarchive-unit-confirmation/unarchive-unit-confirmation.component';
import { ClTabCustomerInfoComponent } from './components/customer-lookup/cl-tab-customer-info/cl-tab-customer-info.component';
import { ClTabCustomerOrderHistoryComponent } from './components/customer-lookup/cl-tab-customer-order-history/cl-tab-customer-order-history.component';
import { ClTabCustomerLoginsComponent } from './components/customer-lookup/cl-tab-customer-logins/cl-tab-customer-logins.component';
import { CustomerInfoComponent } from './components/customer-lookup/cl-tab-customer-info/customer-info/customer-info.component';
import { InvoicingInfoComponent } from './components/customer-lookup/cl-tab-customer-info/invoicing-info/invoicing-info.component';
import { ClArchivedLocationComponent } from './components/customer-lookup/cl-archived-location/cl-archived-location.component';
import { UnarchiveLocationConfirmationComponent } from './components/customer-lookup/cl-archived-location/unarchive-location-confirmation/unarchive-location-confirmation.component';
import { WorkCenterComponent } from './components/work-center/work-center.component';
import { ResetProductionStatusConfirmationComponent } from './components/work-center/reset-production-status-confirmation/reset-production-status-confirmation.component';
import { CommissionRegionComponent } from './components/commissions/commission-region/commission-region.component';
import { ResumeCommissionRegionsComponent } from './components/commissions/resume-commission-regions/resume-commission-regions.component';
import { RoutersComponent } from './components/routers/routers.component';
import { CreateRouterComponent } from './components/routers/create-router/create-router.component';
import { EditRouterComponent } from './components/routers/edit-router/edit-router.component';
import { DeleteRouterComponent } from './components/routers/delete-router/delete-router.component';
import { RouterDetailsComponent } from './components/routers/router-details/router-details.component';
import { CreateRouterSetupComponent } from './components/routers/create-router-setup/create-router-setup.component';
import { EditRouterSetupComponent } from './components/routers/edit-router-setup/edit-router-setup.component';
import { DeleteRouterSetupComponent } from './components/routers/delete-router-setup/delete-router-setup.component';
import { RouterSetupDetailsComponent } from './components/routers/router-setup-details/router-setup-details.component';
import { CreateRouterScheduleComponent } from './components/routers/create-router-schedule/create-router-schedule.component';
import { EditRouterScheduleComponent } from './components/routers/edit-router-schedule/edit-router-schedule.component';
import { DeleteRouterScheduleComponent } from './components/routers/delete-router-schedule/delete-router-schedule.component';
import { RouterScheduleDetailsComponent } from './components/routers/router-schedule-details/router-schedule-details.component';
import { QwikquoteSearchComponent } from './components/customer-lookup/qwikquote-search/qwikquote-search.component';
import { QuoteDetailComponent } from './components/customer-lookup/qwikquote-search/quote-detail/quote-detail.component';
import { ExportToProductionExpandedComponent } from './components/schedule-manager/export-to-production-expanded/export-to-production-expanded.component';
import { EditMaterialSuppliersComponent } from './components/production-calendar/edit-material-suppliers/edit-material-suppliers.component';
import { DeleteExpeditedProductionComponent } from './components/settings/expedited-production/delete-expedited-production/delete-expedited-production.component';
import { EditExpeditedProductionComponent } from './components/settings/expedited-production/edit-expedited-production/edit-expedited-production.component';
import { CreateExpeditedProductionComponent } from './components/settings/expedited-production/create-expedited-production/create-expedited-production.component';
import { DetailExpeditedProductionComponent } from './components/settings/expedited-production/detail-expedited-production/detail-expedited-production.component';
import { PalletizationComponent } from './components/palletization/palletization.component';
import { PalletizationFormComponent } from './components/palletization/palletization-form/palletization-form.component';
import { PalletizationEmailSendingComponent } from './components/palletization/palletization-email-sending/palletization-email-sending.component';
import { PalletizationEmailUpdateModalComponent } from './components/palletization/palletization-email-sending/palletization-email-update-modal/palletization-email-update-modal.component';
import { PalletizationUnitInfoComponent } from './components/palletization/palletization-unit-info/palletization-unit-info.component';
import { UppercaseDirective } from './components/shared/directives/uppercase.directive';
import { CreateShipInSetupComponent } from './components/settings/standard-item-shipping/create-shipping-setup/create-ship-in-setup.component';
import { DeleteShipInSetupComponent } from './components/settings/standard-item-shipping/delete-shipping-setup/delete-ship-in-setup.component';
import { CreateStrategicExpeditedComponent } from './components/settings/strategic-expedited/create-strategic-expedited/create-strategic-expedited.component';
import { EditStrategicExpeditedComponent } from './components/settings/strategic-expedited/edit-strategic-expedited/edit-strategic-expedited.component';
import { DeleteStrategicExpeditedComponent } from './components/settings/strategic-expedited/delete-strategic-expedited/delete-strategic-expedited.component';
import { DetailsStrategicExpeditedComponent } from './components/settings/strategic-expedited/details-strategic-expedited/details-strategic-expedited.component';
import { WhatsNotScheduledComponent } from './components/whats-not-scheduled/whats-not-scheduled.component';
import { FinalQaComponent } from './components/final-qa/final-qa.component';
import { TableSawComponent } from './components/table-saw/table-saw.component';
import { ScheduleLookAheadComponent } from './components/schedule-look-ahead/schedule-look-ahead.component';
const SNACK_BAR_DURATION = 5000;

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SignInComponent,
    PageNotFoundComponent,
    DashboardComponent,
    AccountsComponent,
    RolesComponent,
    SettingsComponent,
    HeaderComponent,
    SidenavComponent,
    SnackbarMessageComponent,
    ForbiddenComponent,
    AccountDetailsComponent,
    CreateAccountComponent,
    EditAccountComponent,
    DeleteAccountComponent,
    ChangePasswordComponent,
    CreateRoleComponent,
    EditRoleComponent,
    RoleDetailsComponent,
    DeleteRoleComponent,
    AccountRolesComponent,
    ProductionCalendarComponent,
    SettingsByGroupNameComponent,
    EditSettingComponent,
    SettingDetailsComponent,
    DeleteSettingComponent,
    ProductionLocationsComponent,
    CreateProductionLocationComponent,
    EditProductionLocationComponent,
    ProductionLocationDetailsComponent,
    DeleteProductionLocationComponent,
    EditProductionCalendarComponent,
    EditScheduledUnitComponent,
    SchedulerNotesDetailsComponent,
    BottomSheetScheduleLegendComponent,
    ExportToProductionComponent,
    ResetPoNumberComponent,
    RouterCimComponent,
    ApartmentsComponent,
    EditProductionOrderComponent,
    ApartmentDetailsComponent,
    ResetPasswordComponent,
    ProductionLimitsComponent,
    CreateProductionLimitComponent,
    EditProductionLimitComponent,
    DeleteProductionLimitComponent,
    ProductionLimitDetailsComponent,
    EditScheduledUnitConfirmationComponent,
    ScheduleManagerComponent,
    ScheduleComponent,
    MiterSawComponent,
    EditTrainComponent,
    RdbFilesComponent,
    GibenComponent,
    ScanLookupsComponent,
    PromoCodesComponent,
    CreatePromoCodeComponent,
    EditPromoCodeComponent,
    DeletePromoCodeComponent,
    PromoCodeDetailsComponent,
    AccountingComponent,
    CreateRebateGroupComponent,
    EditRebateGroupComponent,
    DeleteRebateGroupComponent,
    EditRebateGroupAssignmentsComponent,
    RebateGroupAssignmentsDetailsComponent,
    RebateGroupDetailsComponent,
    RebateReportsComponent,
    CommissionDashboardComponent,
    ResumeCommissionsComponent,
    ManagerCommissionsComponent,
    SettingsCommissionsComponent,
    CommunitiesCommissionsComponent,
    CommunityModalCommissionsComponent,
    SettingsCommissionModalComponent,
    SummaryComponent,
    ScanBarcodesComponent,
    CustomerModalCommissionsComponent,
    PreventRecalculateCommissionComponent,
    CommissionCustomerAccountComponent,
    ReasonCodesComponent,
    ListReasonCodesComponent,
    CreateReasonCodeComponent,
    EditReasonCodeComponent,
    ListReasonCategoriesComponent,
    CreateReasonCategoryComponent,
    EditReasonCategoryComponent,
    ListReasonGroupsComponent,
    CreateReasonGroupComponent,
    EditReasonGroupComponent,
    ReasonGroupsModalComponent,
    ReasonCodesModalComponent,
    OrderNotificationComponent,
    CreateOrderNotificationComponent,
    DetailReadingNotificationComponent,
    WhatsNotComponent,
    AuditComponent,
    AuditAssignmentsDetailsComponent,
    EditAuditAssignmentsComponent,
    OvernightDeliveryComponent,
    OrderSearchComponent,
    AddressDetailsComponent,
    PackageShipmentDetailsComponent,
    RatesTransitTimesComponent,
    EmailSendingConfirmationComponent,
    EmailUpdateModalComponent,
    MultiSelectAutocompleteComponent,
    CustomerLookupComponent,
    ProductionMoldingDetailsComponent,
    EditProductionMoldingComponent,
    DeleteProductionMoldingComponent,
    CreateProductionMoldingComponent,
    AlertOvernightProcessComponent,
    ClUpdateUserModalComponent,
    ClDeleteUserModalComponent,
    ClAddAccountModalComponent,
    ClArchivedUnitComponent,
    UnarchiveUnitConfirmationComponent,
    ClTabCustomerInfoComponent,
    ClTabCustomerOrderHistoryComponent,
    ClTabCustomerLoginsComponent,
    CustomerInfoComponent,
    InvoicingInfoComponent,
    ClArchivedLocationComponent,
    UnarchiveLocationConfirmationComponent,
    WorkCenterComponent,
    ResetProductionStatusConfirmationComponent,
    CommissionRegionComponent,
    ResumeCommissionRegionsComponent,
    RoutersComponent,
    CreateRouterComponent,
    EditRouterComponent,
    DeleteRouterComponent,
    RouterDetailsComponent,
    CreateRouterSetupComponent,
    EditRouterSetupComponent,
    DeleteRouterSetupComponent,
    RouterSetupDetailsComponent,
    CreateRouterScheduleComponent,
    EditRouterScheduleComponent,
    DeleteRouterScheduleComponent,
    RouterScheduleDetailsComponent,
    QwikquoteSearchComponent,
    QuoteDetailComponent,
    ExportToProductionExpandedComponent,
    EditMaterialSuppliersComponent,
    CreateExpeditedProductionComponent,
    DeleteExpeditedProductionComponent,
    EditExpeditedProductionComponent,
    DetailExpeditedProductionComponent,
    EditMaterialSuppliersComponent,
    PalletizationComponent,
    PalletizationFormComponent,
    PalletizationEmailSendingComponent,
    PalletizationEmailUpdateModalComponent,
    PalletizationUnitInfoComponent,
    UppercaseDirective,
    CreateShipInSetupComponent,
    DeleteShipInSetupComponent,
    CreateStrategicExpeditedComponent,
    EditStrategicExpeditedComponent,
    DeleteStrategicExpeditedComponent,
    DetailsStrategicExpeditedComponent,
    WhatsNotScheduledComponent,
    FinalQaComponent,
    TableSawComponent,
    ScheduleLookAheadComponent,
  ],
  entryComponents: [
    SnackbarMessageComponent,
    EditProductionCalendarComponent,
    EditScheduledUnitComponent,
    SchedulerNotesDetailsComponent,
    BottomSheetScheduleLegendComponent,
    ExportToProductionComponent,
    ResetPoNumberComponent,
    EditProductionOrderComponent,
    ApartmentDetailsComponent,
    EditScheduledUnitConfirmationComponent,
    EditTrainComponent,
    RdbFilesComponent,
    EditRebateGroupAssignmentsComponent,
    RebateGroupAssignmentsDetailsComponent,
    RebateReportsComponent,
    ResetProductionStatusConfirmationComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    MatSortModule,
    HttpClientModule,
    FullCalendarModule,
    PipesModule,
    IMaskModule,
    // SelectAutocompleteModule,
    MatGridListModule,
    NgxWebstorageModule.forRoot(),
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: SNACK_BAR_DURATION } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomerAuthInterceptorInterceptor, multi: true },
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: {
        _forceAnimations: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
