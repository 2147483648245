import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FormattedScheduledUnit } from '../../models/formatted-scheduled-unit';
import { MultipleScheduledUnits } from '../../models/multiple-scheduled-units';
import { ProductionCalendar } from '../../models/production-calendar';
import { ProductionLocation } from '../../models/production-location';
import {
  BottomSheetScheduleLegendComponent,
} from '../shared/bottom-sheet-schedule-legend/bottom-sheet-schedule-legend.component';
import { ScheduledUnit } from '../../models/scheduled-unit';
import { ScheduledUnitsBySalesOrder } from '../../models/scheduled-units-by-salesorder';
import { ProductionCalendarService } from '../../services/production-calendar.service';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ScheduleService } from '../../services/schedule.service';
import { SnackbarService } from '../../services/snackbar.service';
import { ProductionStatus } from '../../models/production-status';
import { EditScheduledUnitComponent } from './edit-scheduled-unit/edit-scheduled-unit.component';
import { ResetPoNumberComponent } from './reset-po-number/reset-po-number.component';
import { SchedulerNotesDetailsComponent } from './scheduler-notes-details/scheduler-notes-details.component';
import { ProductionStatusesService } from '../../services/production-statuses.service';
import { JobStatus, JobState } from '../../models/job-status';
import { SCHED_JOB } from '../../constants';
import * as moment from 'moment';
import { Color } from '../../models/color';
import { DoorStyle } from '../../models/door-style';
import { Subscription } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

const NOT_FOUND = 404;
const INVALID_LOCATION_ID = -1;
const LOADING_ANIMATION_TIMEOUT = 500; // 0.5 sec
const SCHEDULE_JOB_STATUS_INTERVAL = 30000; // 30 sec
const STANDARD_SLAB_PART = 'standard slab';
const SHOW_ALL = 'show-all';

@Component({
  selector: 'app-schedule-manager',
  templateUrl: './schedule-manager.component.html',
  styleUrls: ['./schedule-manager.component.less']
})
export class ScheduleManagerComponent implements OnInit, AfterViewInit, OnDestroy {

  loading = false;
  fullScreenMode = false;
  allowedSuggestSchedule = true;
  allowedSynchronizeSchedule = false;
  scheduleSyncStartTime = '';
  customPartsCapacity = -1;
  allowedCustomPartsCapacity = -1;
  mousedown = false;
  beginDate = new FormControl();
  productionLocationId = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];
  productionStatusKeys: string[] = [];
  productionStatuses: Map<string, string> = new Map<string, string>();
  colors: Color[] = [];
  doorStyles: DoorStyle[] = [];
  shipMethods: string[] = [];
  productionOrderNumbers = [];
  allowedToggleView = false;
  suggestScheduleExecuted = false;

  // columns
  displayedColumns: string[] = ['select', 'project', 'customer', 'customerNumber', 'schedulerNotes', 'overnightServiceType', 'apartmentNumber', 'salesOrderNumber',
    'numberOfParts', 'partialUnitId', 'orderDate', 'productionDate', 'productionLocationId', 'sequence', 'requestedDeliveryDate',
    'mustShipDate', 'transitDays', 'fedExTransitDays', 'color', 'style', 'audit', 'friendlyStatus', 'productionOrderNumber', 'moldingLength', 'channelGroove', 'plannedShipDate', 'expedited', 'shipMethod', 'carrier'];

  // filters
  filtersApplied = false;
  filterOptions = ['Hot / Overnight', 'Multi Color', 'Parts', 'Accessories', 'Regular', 'LockedIn', 'Sample', 'No Capacity Found'];

  customerFilter = new FormControl();
  salesOrderNumberFilter = new FormControl();
  projectFilter = new FormControl();
  apartmentFilter = new FormControl();
  colorFilter = new FormControl([]);
  doorStyleFilter = new FormControl([]);
  customerNumberFilter = new FormControl();
  productionOrderNumbersFilter = new FormControl([]);
  orderDateFilter = new FormControl();
  productionDateFilter = new FormControl();
  requestedShipDateFilter = new FormControl();
  mustShipDateFilter = new FormControl();
  productionStatusFilter = new FormControl([]);
  shipMethodFilter = new FormControl([]);

  hotFilter = false;
  overnightFilter = false;
  multicolorFilter = false;
  partsFilter = false;
  accessoriesFilter = false;
  noAccessoriesFilter = false;
  regularFilter = false;
  lockedInFilter = false;
  noLockedInFilter = false;
  sampleFilter = false;
  noCapacityFoundFilter = false;
  hasPONumberFilter = false;
  noHasPONumberFilter = false;
  retailFilter = false;
  palletFilter = false;
  fusionFilter = false;
  auditFilter = false;
  whatsNotFilter = false;
  jitFilter = false;
  noJitFilter = false;
  expeditedFilter = false;
  noExpeditedFilter = false;

  maxSelectedIndex = -1;
  minSelectedIndex = -1;
  scheduledUnitsSubscription: Subscription;
  scheduledUnitsSelection = new SelectionModel<number>(true, []);
  scheduledUnitsDataSource = new MatTableDataSource<FormattedScheduledUnit>();
  filteredScheduledUnitsDataSource = new MatTableDataSource<FormattedScheduledUnit>();
  editFormPanelDisplaced = true;
  filterFormPanelDisplaced = true;
  totalCustomPartsSelected = 0;
  totalSlabPartsSelected = 0;
  totalShakerPartsSelected = 0;
  totalAccessoriesSelected = 0;

  // edit multiple scheduled units controls
  productionDate = new FormControl();
  productionStatus = new FormControl();
  schedulerNotes = new FormControl();
  productionOrderNumber = new FormControl();
  isLockedIn = false;
  isHot = false;
  isOvernight = false;
  isAudit = false;
  isPalletized = false;

  scheduledUnitSort: MatSort;

  @ViewChild('scheduledUnitSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.scheduledUnitSort = ms;
    this.ngAfterViewInit();
  }

  constructor(
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private bottomSheetScheduleLegend: MatBottomSheet,
    private scheduleService: ScheduleService,
    private snackBarService: SnackbarService,
    private productionStatusesService: ProductionStatusesService,
    private productionLocationsService: ProductionLocationsService,
    private productionCalendarService: ProductionCalendarService,
  ) { }

  async ngOnInit() {
    this.fullScreenMode = this.getFullScreenMode();
    this.getColors();
    this.getDoorStyles();
    this.getShipMethods();
    this.getProductionStatuses();
    this.getScheduleJobStatus();
    await this.getProductionLocations();

    if (this.productionLocationId === INVALID_LOCATION_ID || !this.beginDate.value) {
      this.disableSuggestSchedule();
    }

    this.setBeginDate(this.productionLocationId, new Date(), true);
  }

  async refresh() {
    await this.suggestSchedule(false);
  }

  ngOnDestroy() {
    // clear scheduled data and subscription
    if (this.scheduledUnitsSubscription) {
      this.scheduledUnitsSubscription.unsubscribe();
    }
    this.scheduledUnitsDataSource.disconnect();
    this.scheduledUnitsDataSource.data.length = 0;
    this.filteredScheduledUnitsDataSource.disconnect();
    this.filteredScheduledUnitsDataSource.data.length = 0;
  }

  setProductionOrderNumbers() {
    const productionOrderNumbers = new Set();
    this.scheduledUnitsDataSource.data
      .filter(p => p.productionOrderNumber)
      .forEach(p => {
        productionOrderNumbers.add(p.productionOrderNumber);
      });
    this.productionOrderNumbers = Array.from(productionOrderNumbers).map(p => {
      return { value: p };
    });
  }

  getDisplaced(): string {
    if (this.editFormPanelDisplaced && this.filterFormPanelDisplaced) { return 'displaced-3'; }
    if (this.editFormPanelDisplaced) { return 'displaced-2'; }
    if (this.filterFormPanelDisplaced) { return 'displaced-1'; }
    return '';
  }

  ngAfterViewInit() {
    // sort options
    this.scheduledUnitsDataSource.sort = this.scheduledUnitSort;
    this.filteredScheduledUnitsDataSource.sort = this.scheduledUnitSort;
  }

  scheduledUnitsViewModeActive() {
    return !this.loading && this.suggestScheduleExecuted;
  }

  openBottomSheetScheduleLegend() {
    this.bottomSheetScheduleLegend.open(BottomSheetScheduleLegendComponent);
  }

  getFullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  onMousedown(event, index) {
    if (!event.shiftKey && !event.ctrlKey) {
      this.mousedown = true;
      this.minSelectedIndex = index;
    }
  }

  onMouseenter(event, index) {
    if (this.mousedown) {
      this.selectRow(index);
    }
  }

  onMouseleave(event, index) {
    if (this.mousedown) {
      this.selectRow(index);
    }
  }

  onMouseup(event, index) {
    if (!event.shiftKey && !event.ctrlKey && this.mousedown) {
      this.maxSelectedIndex = index;
      const minIndex = Math.min(this.minSelectedIndex, this.maxSelectedIndex);
      const maxIndex = Math.max(this.minSelectedIndex, this.maxSelectedIndex);

      this.minSelectedIndex = minIndex;
      this.maxSelectedIndex = maxIndex;

      if (this.minSelectedIndex !== this.maxSelectedIndex) {
        this.selectRows(this.minSelectedIndex, this.maxSelectedIndex);
      }
      this.clearSelectedIndexes();
    }
  }

  onHasPONumberFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.noHasPONumberFilter) {
      this.noHasPONumberFilter = false;
    }
  }

  onNoHasPONumberFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.hasPONumberFilter) {
      this.hasPONumberFilter = false;
    }
  }

  onLockedInFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.noLockedInFilter) {
      this.noLockedInFilter = false;
    }
  }

  onNoLockedInFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.lockedInFilter) {
      this.lockedInFilter = false;
    }
  }

  onJitFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.noJitFilter) {
      this.noJitFilter = false;
    }
  }

  onNoJitFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.jitFilter) {
      this.jitFilter = false;
    }
  }

  onExpeditedFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.noExpeditedFilter) {
      this.noExpeditedFilter = false;
    }
  }

  onNoExpeditedFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.expeditedFilter) {
      this.expeditedFilter = false;
    }
  }

  toggleAccessoriesFilter(e: MatCheckboxChange) {
    this.noAccessoriesFilter = this.noAccessoriesFilter && !e.checked;
  }

  toggleNoAccessoriesFilter(e: MatCheckboxChange) {
    this.accessoriesFilter = this.accessoriesFilter && !e.checked;
  }

  clearSelectedIndexes() {
    this.mousedown = false;
    this.minSelectedIndex = -1;
    this.maxSelectedIndex = -1;
  }

  clearSelection() {
    this.scheduledUnitsSelection.clear();
    this.clearScheduledUnitsSummary();
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.fullScreenMode = this.getFullScreenMode();
  }

  isAllSelected() {
    const numSelected = this.scheduledUnitsSelection.selected.length;
    const numRows = this.getData().length;
    return numSelected >= numRows;
  }

  onScheduledUnitsTableRowClick(event, index) {
    if (event.shiftKey) {
      this.minSelectedIndex = this.minSelectedIndex === -1 ? index : this.minSelectedIndex;
      this.maxSelectedIndex = this.maxSelectedIndex === -1 ? index : this.maxSelectedIndex;

      this.minSelectedIndex = Math.min(this.minSelectedIndex, index);
      this.maxSelectedIndex = Math.max(this.maxSelectedIndex, index);

      this.selectRows(this.minSelectedIndex, this.maxSelectedIndex);
    }

    if (event.ctrlKey) {
      this.toggleRow(index);
    }
  }

  selectRows(start: number, end: number) {
    this.scheduledUnitsSelection.clear();
    this.clearScheduledUnitsSummary();

    for (let i = start; i <= end; i++) {
      this.selectRow(i);
    }
  }

  selectRow(index: number) {
    const data = this.getData();
    const row = data[index];
    if (row) {
      this.increaseSelectedScheduledUnitsSummary(row);
      this.scheduledUnitsSelection.select(row.partialUnitId);
    }
  }

  increaseSelectedScheduledUnitsSummary(item: FormattedScheduledUnit | ScheduledUnitsBySalesOrder) {
    if (item.isAccessories) {
      this.addAccessories(item.numberOfParts);
    } else {
      this.addCustomParts(item);
    }
  }

  decreaseSelectedScheduledUnitsSummary(item: FormattedScheduledUnit | ScheduledUnitsBySalesOrder) {
    if (item.isAccessories) {
      this.removeAccessories(item.numberOfParts);
    } else {
      this.removeCustomParts(item);
    }
  }

  toggleScheduledUnitsSelection(event: MatCheckboxChange, element: FormattedScheduledUnit) {
    if (event.checked) {
      this.increaseSelectedScheduledUnitsSummary(element);
      this.scheduledUnitsSelection.select(element.partialUnitId);
    } else {
      this.scheduledUnitsSelection.deselect(element.partialUnitId);
      this.decreaseSelectedScheduledUnitsSummary(element);
    }
  }

  isScheduledUnit(item: FormattedScheduledUnit | ScheduledUnitsBySalesOrder): item is FormattedScheduledUnit {
    return (item as FormattedScheduledUnit).color !== undefined;
  }

  isSlabPart(item: FormattedScheduledUnit | ScheduledUnitsBySalesOrder): boolean {
    return this.isScheduledUnit(item) && item.style.toLowerCase() === STANDARD_SLAB_PART;
  }

  isShakerPart(item: FormattedScheduledUnit | ScheduledUnitsBySalesOrder): boolean {
    return this.isScheduledUnit(item) && item.style.toLowerCase() !== '' && item.style.toLowerCase() !== STANDARD_SLAB_PART;
  }

  addCustomParts(item: FormattedScheduledUnit | ScheduledUnitsBySalesOrder) {
    this.totalSlabPartsSelected += this.isSlabPart(item) ? item.numberOfParts : 0;
    this.totalShakerPartsSelected += this.isShakerPart(item) ? item.numberOfParts : 0;
    this.totalCustomPartsSelected += item.numberOfParts;
  }

  addAccessories(totalAccessories: number) {
    this.totalAccessoriesSelected += totalAccessories;
  }

  removeCustomParts(item: FormattedScheduledUnit | ScheduledUnitsBySalesOrder) {
    this.totalSlabPartsSelected -= this.isSlabPart(item) ? item.numberOfParts : 0;
    this.totalShakerPartsSelected -= this.isShakerPart(item) ? item.numberOfParts : 0;
    this.totalCustomPartsSelected -= item.numberOfParts;
  }

  removeAccessories(totalAccessories: number) {
    this.totalAccessoriesSelected -= totalAccessories;
  }

  clearScheduledUnitsSummary() {
    this.totalCustomPartsSelected = 0;
    this.totalAccessoriesSelected = 0;
    this.totalSlabPartsSelected = 0;
    this.totalShakerPartsSelected = 0;
  }

  toggleRow(index: number) {
    this.toggleRowOnScheduledUnitsViewMode(index);
  }

  toggleRowOnScheduledUnitsViewMode(index: number) {
    const data = this.getData();
    const row = data[index];

    if (row) {
      this.scheduledUnitsSelection.toggle(row.partialUnitId);

      if (this.scheduledUnitsSelection.isSelected(row.partialUnitId)) {
        this.increaseSelectedScheduledUnitsSummary(row);
      } else {
        this.decreaseSelectedScheduledUnitsSummary(row);
      }
    }
  }

  masterToggle() {
    this.minSelectedIndex = -1;
    this.maxSelectedIndex = -1;
    this.clearScheduledUnitsSummary();

    if (!this.isAllSelected()) {
      this.minSelectedIndex = 0;
      this.maxSelectedIndex = this.scheduledUnitsDataSource.data.length - 1;
      this.getData().forEach(row => {
        this.increaseSelectedScheduledUnitsSummary(row);
        this.scheduledUnitsSelection.select(row.partialUnitId);
      });
    } else {
      this.scheduledUnitsSelection.clear();
    }
  }

  getColors() {
    this.productionCalendarService.getColors().subscribe((colors: Color[]) => {
      this.colors = colors;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    });
  }

  getDoorStyles() {
    this.productionCalendarService.getDoorStyles().subscribe((doorStyles: DoorStyle[]) => {
      this.doorStyles = doorStyles;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting door styles.');
    });
  }

  getShipMethods() {
    this.scheduleService.getShipMethods().subscribe((shipMethods: string[]) => {
      this.shipMethods = shipMethods;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting ship methods.');
    });
  }

  async getProductionStatuses() {
    const statuses = await this.productionStatusesService.getProductionStatuses().toPromise();
    for (const status of Object.keys(statuses)) {
      this.productionStatuses.set(status, statuses[status]);
      this.productionStatusKeys.push(status);
    }
  }

  getIndexOfProductionStatus(status: string): number {
    const value = ProductionStatus[status];
    return value;
  }

  getData(): FormattedScheduledUnit[] {
    return this.getScheduledUnitsDataSource().connect().value;
  }

  onScheduledUnitsTableClick(event) {
    const partialUnitId = this.findAttributeScheduledUnitId(event);

    if ((event.target.tagName === 'SPAN' && event.target.className.includes('notes')) && partialUnitId) {
      const { schedulerNotes } = this.findScheduledUnit(partialUnitId);
      if (schedulerNotes) {
        this.openSchedulerNotesDetailsModal(schedulerNotes);
      }
    }

    if ((event.target.tagName === 'SPAN' && event.target.className.includes('poNumber')) && partialUnitId) {
      const { productionOrderNumber } = this.findScheduledUnit(partialUnitId);
      if (productionOrderNumber) {
        this.copyToClipboard(productionOrderNumber);
      }
    }
  }

  onScheduledUnitsTableDblClick(event) {
    const partialUnitId = this.findAttributeScheduledUnitId(event);
    if (partialUnitId) {
      this.openEditScheduledUnit(partialUnitId);
    }
  }

  getSelectedScheduledUnitsIds(): number[] {
    return this.scheduledUnitsSelection.selected;
  }

  setProductionDate() {
    const ids = this.getSelectedScheduledUnitsIds();
    if (ids.length === 0) {
      this.snackBarService.showWarning('You must select at least 1 scheduled unit.');
    } else if (this.productionDate.valid && !this.productionDate.value) {
      this.snackBarService.showWarning('You must provide a production date.');
    } else {
      const model: MultipleScheduledUnits = {
        ids,
        productionDate: this.productionDate.value
      };
      this.updateMultipleScheduledUnits(model).then(() => {
        this.clearSelection();
      });
    }
  }

  async setProductionDateAndLock() {
    const ids = this.getSelectedScheduledUnitsIds();
    if (ids.length === 0) {
      this.snackBarService.showWarning('You must select at least 1 scheduled unit.');
    } else if (this.productionDate.valid && !this.productionDate.value) {
      this.snackBarService.showWarning('You must provide a production date.');
    } else {
      const model: MultipleScheduledUnits = {
        ids,
        productionDate: this.productionDate.value,
        isLockedIn: true
      };
      this.updateMultipleScheduledUnits(model).then(() => {
        this.clearSelection();
      });
    }
  }

  setProductionStatus() {
    const ids = this.getSelectedScheduledUnitsIds();
    if (ids.length === 0) {
      this.snackBarService.showWarning('You must select at least 1 scheduled unit.');
    } else if (this.productionStatus.valid && !this.productionStatus.value) {
      this.snackBarService.showWarning('You must provide a production status.');
    } else {
      const model: MultipleScheduledUnits = {
        ids,
        status: this.productionStatus.value,
        friendlyStatus: this.productionStatuses.get(this.productionStatus.value)
      };
      this.updateMultipleScheduledUnits(model).then(() => {
        this.clearSelection();
      });
    }
  }

  setNote() {
    const ids = this.getSelectedScheduledUnitsIds();
    if (ids.length === 0) {
      this.snackBarService.showWarning('You must select at least 1 scheduled unit.');
    } else if (this.schedulerNotes.valid && !this.schedulerNotes.value) {
      this.snackBarService.showWarning('You must enter a note.');
    } else {
      const model: MultipleScheduledUnits = {
        ids,
        note: this.schedulerNotes.value.trim()
      };
      this.updateMultipleScheduledUnits(model).then(() => {
        this.clearSelection();
      });
    }
  }

  setPalletized() {
    const ids = this.getSelectedScheduledUnitsIds();
    if (ids.length === 0) {
      this.snackBarService.showWarning('You must select at least 1 scheduled unit.');
    } else {
      const model: MultipleScheduledUnits = {
        ids,
        isPalletized: this.isPalletized
      };
      this.updateMultipleScheduledUnits(model);
    }
  }

  setLockedIn() {
    const ids = this.getSelectedScheduledUnitsIds();
    if (ids.length === 0) {
      this.snackBarService.showWarning('You must select at least 1 scheduled unit.');
    } else {
      const model: MultipleScheduledUnits = {
        ids,
        isLockedIn: this.isLockedIn
      };
      this.updateMultipleScheduledUnits(model);
    }
  }

  setHot() {
    const ids = this.getSelectedScheduledUnitsIds();
    if (ids.length === 0) {
      this.snackBarService.showWarning('You must select at least 1 scheduled unit.');
    } else {
      const model: MultipleScheduledUnits = {
        ids,
        isHot: this.isHot
      };
      this.updateMultipleScheduledUnits(model);
    }
  }

  setOvernight() {
    const ids = this.getSelectedScheduledUnitsIds();
    if (ids.length === 0) {
      this.snackBarService.showWarning('You must select at least 1 scheduled unit.');
    } else {
      const model: MultipleScheduledUnits = {
        ids,
        isOvernight: this.isOvernight
      };
      this.updateMultipleScheduledUnits(model);
    }
  }

  setAudit() {
    const ids = this.getSelectedScheduledUnitsIds();
    if (ids.length === 0) {
      this.snackBarService.showWarning('You must select at least 1 scheduled unit.');
    } else {
      const model: MultipleScheduledUnits = {
        ids,
        isAudit: this.isAudit
      };
      this.updateMultipleScheduledUnits(model);
    }
  }

  updateMultipleScheduledUnits(model: MultipleScheduledUnits): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let canApplyUpdates = true;
      const updateCallback = () => {
        this.scheduleService.updateMultipleScheduledUnits(model).subscribe((result: MultipleScheduledUnits) => {
          this.clearEditForm();
          this.refreshMultipleScheduledUnits(result);
          this.snackBarService.showSuccess('The scheduled units information was updated successfully.');
          resolve();
        }, err => {
          console.error(err);
          this.snackBarService.showError('Error updating multiple scheduled units.');
          reject();
        });
      };

      for (const id of model.ids) {
        const scheduledUnit = this.findScheduledUnit(id);
        if (scheduledUnit.isLockedIn
          && scheduledUnit.productionOrderNumber
          && scheduledUnit.productionDate
          && (model.hasOwnProperty('isLockedIn') && !model.hasOwnProperty('productionDate'))) {
          canApplyUpdates = false;
          break;
        }
      }

      if (!canApplyUpdates) {
        this.snackBarService.showWarning('Scheduled units has already been exported to production. You can\'t change the locked-in values.');
        return;
      }

      updateCallback();
    });
  }

  refreshMultipleScheduledUnits(model: MultipleScheduledUnits) {
    if (model && model.ids) {
      for (const partialUnitId of model.ids) {
        const schedUnit = this.findScheduledUnit(partialUnitId);
        const relatedSchedUnits = this.findScheduledUnitsByUniqueUnitId(schedUnit);

        for (const partialUnit of relatedSchedUnits) {
          if (model.productionDate !== null) {
            partialUnit.productionDate = model.productionDate;
            partialUnit.productionDateStr = model.productionDateStr;
            partialUnit.classType = model.classType;
          }

          if (model.isLockedIn !== null) {
            partialUnit.isLockedIn = model.isLockedIn;
          }

          if (model.isHot !== null) {
            partialUnit.isHot = model.isHot;
          }

          if (model.isOvernight !== null) {
            partialUnit.isOvernight = model.isOvernight;
          }

          if (model.isAudit !== null) {
            partialUnit.isAudit = model.isAudit;
            partialUnit.isAuditStr = model.isAuditStr;
          }

          if (model.isPalletized !== null) {
            partialUnit.isPalletized = model.isPalletized;
            partialUnit.isPartsOrder = !model.isPalletized && partialUnit.isPartsOrder;
          }
        }

        if (model.note !== null) {
          schedUnit.schedulerNotes = model.note;
        }

        if (model.status !== null) {
          schedUnit.status = model.status;
          schedUnit.friendlyStatus = model.friendlyStatus;
        }
      }
    }
  }

  onLegendPanelOpened() {
    this.filterFormPanelDisplaced = true;
  }

  onLegendPanelClosed() {
    this.filterFormPanelDisplaced = false;
  }

  onFiltersPanelOpened() {
    this.editFormPanelDisplaced = true;
  }

  onFiltersPanelClosed() {
    this.editFormPanelDisplaced = false;
  }

  findAttributeScheduledUnitId(event): number {
    let schedUnitId = 0;
    const path = event.path || (event.composedPath && event.composedPath()) || [];
    for (let i = 0; i < path.length; i++) {
      const el = path[i];
      if (el.tagName === 'TR' && el.getAttribute('sched-unit-id')) {
        schedUnitId = parseInt(el.getAttribute('sched-unit-id'), 10);
        break;
      }
    }
    return schedUnitId;
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        const defaultLocation = productionLocations.find(p => p.isDefault);
        this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }

  filterByHot(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isHot);
  }

  filterByOvernight(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isOvernight);
  }

  filterByPartsOrders(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isPartsOrder);
  }

  filterByAccessories(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isAccessories);
  }

  filterByNoAccessories(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.isAccessories);
  }

  filterByMultiColors(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isMultiColor);
  }

  filterByLockedIn(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isLockedIn);
  }

  filterByNoLockedIn(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.isLockedIn);
  }

  filterByJIT(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.jit);
  }

  filterByNoJIT(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.jit);
  }

  filterByAudit(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isAudit);
  }

  filterByTestUnits(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isTestUnit);
  }

  filterByRegularOrders(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isRegularOrder);
  }

  filterByProject() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.project.toLowerCase().includes(this.projectFilter.value.toLowerCase()));
  }

  filterByCustomer() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.customer.toLowerCase().includes(this.customerFilter.value.toLowerCase()));
  }

  filterByExpedited(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isExpedited);
  }

  filterByNoExpedited(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.isExpedited);
  }

  filterByColor() {
    const result = [];
    const colors = this.colorFilter.value.filter(c => c);
    for (const color of colors) {
      if (color === SHOW_ALL) {
        return this.filteredScheduledUnitsDataSource.data;
      }
      const data = this.filteredScheduledUnitsDataSource.data.filter(u => u.color === color);
      result.push(...data);
    }
    return result;
  }

  filterByDoorStyle() {
    const result = [];
    const doorStyles = this.doorStyleFilter.value.filter(c => c);
    for (const doorStyle of doorStyles) {
      if (doorStyle === SHOW_ALL) {
        return this.filteredScheduledUnitsDataSource.data;
      }
      const data = this.filteredScheduledUnitsDataSource.data.filter(u => u.style === doorStyle);
      result.push(...data);
    }
    return result;
  }

  filterCustomerNumber() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.customerNumber?.toLowerCase()
      .includes(this.customerNumberFilter?.value.toLowerCase()));
  }

  filterBySONumber() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.salesOrderNumber.toLowerCase()
      .includes(this.salesOrderNumberFilter.value.toLowerCase()));
  }

  filterByPONumbers() {
    const result = [];
    const poNumbers = this.productionOrderNumbersFilter.value.map(p => p.toLowerCase());

    for (const poNumber of poNumbers) {
      const data = this.filteredScheduledUnitsDataSource.data.filter(u => u.productionOrderNumber && u.productionOrderNumber.toLowerCase().includes(poNumber));
      result.push(...data);
    }
    return result;
  }

  filterByNoCapacityFound() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.productionDate);
  }

  filterByHasPONumber() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.productionOrderNumber);
  }

  filterByNoHasPONumber() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.productionOrderNumber);
  }

  filterByPartialUnitId(partialUnitId: number) {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.partialUnitId === partialUnitId);
  }

  filterByProductionStatus() {
    const result = [];
    const productionStatuses = this.productionStatusFilter.value.filter(c => c)
      .map(c => c.trim().toLowerCase());

    for (const status of productionStatuses) {
      if (status === SHOW_ALL) {
        return this.filteredScheduledUnitsDataSource.data;
      }
      const data = this.filteredScheduledUnitsDataSource.data.filter(u => u.status.trim().toLowerCase() === status);
      result.push(...data);
    }
    return result;
  }

  filterByShipMethod() {
    const result = [];
    const methods = this.shipMethodFilter.value.filter(c => c);
    for (const method of methods) {
      if (method === SHOW_ALL) {
        return this.filteredScheduledUnitsDataSource.data;
      }
      const data = this.filteredScheduledUnitsDataSource.data.filter(u => u.shipMethod === method);
      result.push(...data);
    }
    return result;
  }

  filterByWhatsNotProductionStatus() {
    const result = [];
    const productionStatuses = this.productionStatusFilter.value.filter(c => c)
      .map(c => c.trim());

    for (const productionStatus of productionStatuses) {
      if (productionStatus === SHOW_ALL) {
        return this.filteredScheduledUnitsDataSource.data;
      }
      const status = this.getIndexOfProductionStatus(productionStatus);
      const data = this.filteredScheduledUnitsDataSource.data.filter(u => this.getIndexOfProductionStatus(u.status.trim()) < status);
      result.push(...data);
    }
    return result;
  }

  equals(date1: string | Date, date2: string | Date): boolean {
    const a = new Date(date1);
    const b = new Date(date2);
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }

  filterByOrderDate() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.productionDate && this.equals(this.orderDateFilter.value, u.orderDate));
  }

  filterByProductionDate() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.productionDate && this.equals(this.productionDateFilter.value, u.productionDate));
  }

  filterByMustShipDate() {

    return this.filteredScheduledUnitsDataSource.data.filter(u => u.mustShipDate && this.equals(this.mustShipDateFilter.value, u.mustShipDate));
  }

  filterByRequestedShipDate() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.requestedShipDate
      && this.equals(this.requestedShipDateFilter.value, u.requestedShipDate));
  }

  filterByRetail(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isRetail);
  }

  filterByFusion(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isFusion);
  }

  filterByPallet(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isPalletized);
  }

  filterByApartment() {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.apartmentNumber.toLowerCase().includes(this.apartmentFilter.value.toLowerCase()));
  }

  onMultipleSelectionChange(e: MatSelectChange) {
    for (const value of e.value) {
      if (value === SHOW_ALL) {
        e.source.value = [SHOW_ALL];
        break;
      }
    }
  }

  onBeginDateChange() {
    if (!this.beginDate.valid) {
      this.snackBarService.showWarning('Invalid begin date.');
      this.beginDate.setValue('');
    } else {
      this.setBeginDate(this.productionLocationId, this.beginDate.value);
    }
    this.suggestScheduleExecuted = false;
  }

  setBeginDate(productionLocationId: number, date: Date, showErrors = true) {
    this.productionCalendarService.getProductionCalendarBy(productionLocationId, date)
      .subscribe((productionCalendar: ProductionCalendar) => {
        this.beginDate.setValue(date);
        this.customPartsCapacity = productionCalendar.customPartsCapacity;
        this.allowedCustomPartsCapacity = productionCalendar.allowedCustomPartsCapacity;
        this.enableSuggestSchedule();
      }, err => {
        if (showErrors) {
          if (err.status !== NOT_FOUND) {
            this.snackBarService.showError('Error getting production calendar capacities. Please try again.');
          } else {
            this.snackBarService.showError(`We don\'t have production calendar capacities for this day.
              Please ensure that you set production calendar capacity for this day.`);
          }
        }
        console.error(err);
        this.beginDate.setValue('');
        this.customPartsCapacity = -1;
        this.allowedCustomPartsCapacity = -1;
        this.disableSuggestSchedule();
      });
  }

  onProductionLocationChange(event: MatSelectChange) {
    this.productionLocationId = event.value;
    this.onBeginDateChange();
  }

  onOrderDateFilterChange() {
    if (!this.orderDateFilter.valid) {
      this.snackBarService.showWarning('Invalid order date.');
      this.orderDateFilter.setValue('');
    }
  }

  onProductionDateChange() {
    if (!this.productionDate.valid) {
      this.snackBarService.showWarning('Invalid production date.');
      this.productionDate.setValue('');
    }
  }

  onProductionDateFilterChange() {
    if (!this.productionDateFilter.valid) {
      this.snackBarService.showWarning('Invalid production date.');
      this.productionDateFilter.setValue('');
    }
  }

  onMustShipDateFilterChange() {
    if (!this.mustShipDateFilter.valid) {
      this.snackBarService.showWarning('Invalid must ship date.');
      this.mustShipDateFilter.setValue('');
    }
  }

  onRequestedShipDateFilterChange() {
    if (!this.requestedShipDateFilter.valid) {
      this.snackBarService.showWarning('Invalid requested ship date.');
      this.requestedShipDateFilter.setValue('');
    }
  }

  getScheduledUnitsDataSource(): MatTableDataSource<FormattedScheduledUnit> {
    return this.filtersApplied ? this.filteredScheduledUnitsDataSource : this.scheduledUnitsDataSource;
  }

  applyFilters() {
    this.showLoading();

    this.filteredScheduledUnitsDataSource.data = this.scheduledUnitsDataSource.data;
    this.clearSelection();

    if (this.customerFilter.valid && this.customerFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByCustomer();
      this.filtersApplied = true;
    }
    if (this.salesOrderNumberFilter.valid && this.salesOrderNumberFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterBySONumber();
      this.filtersApplied = true;
    }
    if (this.projectFilter.valid && this.projectFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByProject();
      this.filtersApplied = true;
    }
    if (this.apartmentFilter.valid && this.apartmentFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByApartment();
      this.filtersApplied = true;
    }
    if (this.colorFilter.valid && this.colorFilter.value.length > 0) {
      this.filteredScheduledUnitsDataSource.data = this.filterByColor();
      this.filtersApplied = true;
    }
    if (this.doorStyleFilter.valid && this.doorStyleFilter.value.length > 0) {
      this.filteredScheduledUnitsDataSource.data = this.filterByDoorStyle();
      this.filtersApplied = true;
    }
    if (this.customerNumberFilter.valid && this.customerNumberFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterCustomerNumber();
      this.filtersApplied = true;
    }
    if (this.productionOrderNumbersFilter.valid && this.productionOrderNumbersFilter.value.length > 0) {
      this.filteredScheduledUnitsDataSource.data = this.filterByPONumbers();
      this.filtersApplied = true;
    }
    if (this.orderDateFilter.valid && this.orderDateFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByOrderDate();
      this.filtersApplied = true;
    }
    if (this.productionDateFilter.valid && this.productionDateFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByProductionDate();
      this.filtersApplied = true;
    }
    if (this.requestedShipDateFilter.valid && this.requestedShipDateFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByRequestedShipDate();
      this.filtersApplied = true;
    }
    if (this.mustShipDateFilter.valid && this.mustShipDateFilter.value) {
      this.filteredScheduledUnitsDataSource.data = this.filterByMustShipDate();
      this.filtersApplied = true;
    }
    if (this.productionStatusFilter.valid && this.productionStatusFilter.value.length > 0) {
      if (this.whatsNotFilter) {
        this.filteredScheduledUnitsDataSource.data = this.filterByWhatsNotProductionStatus();
      } else {
        this.filteredScheduledUnitsDataSource.data = this.filterByProductionStatus();
      }
      this.filtersApplied = true;
    }
    if (this.shipMethodFilter.valid && this.shipMethodFilter.value.length > 0) {
      this.filteredScheduledUnitsDataSource.data = this.filterByShipMethod();
      this.filtersApplied = true;
    }
    if (this.hotFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByHot();
      this.filtersApplied = true;
    }
    if (this.overnightFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByOvernight();
      this.filtersApplied = true;
    }
    if (this.multicolorFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByMultiColors();
      this.filtersApplied = true;
    }
    if (this.partsFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByPartsOrders();
      this.filtersApplied = true;
    }
    if (this.accessoriesFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByAccessories();
      this.filtersApplied = true;
    }
    if (this.noAccessoriesFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoAccessories();
      this.filtersApplied = true;
    }
    if (this.regularFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByRegularOrders();
      this.filtersApplied = true;
    }
    if (this.lockedInFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByLockedIn();
      this.filtersApplied = true;
    }
    if (this.noLockedInFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoLockedIn();
      this.filtersApplied = true;
    }
    if (this.sampleFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByTestUnits();
      this.filtersApplied = true;
    }
    if (this.hasPONumberFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByHasPONumber();
      this.filtersApplied = true;
    }
    if (this.noHasPONumberFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoHasPONumber();
      this.filtersApplied = true;
    }
    if (this.noCapacityFoundFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoCapacityFound();
      this.filtersApplied = true;
    }
    if (this.retailFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByRetail();
      this.filtersApplied = true;
    }
    if (this.fusionFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByFusion();
      this.filtersApplied = true;
    }
    if (this.palletFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByPallet();
      this.filtersApplied = true;
    }
    if (this.auditFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByAudit();
      this.filtersApplied = true;
    }
    if (this.jitFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByJIT();
      this.filtersApplied = true;
    }
    if (this.noJitFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoJIT();
      this.filtersApplied = true;
    }
    if (this.expeditedFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByExpedited();
      this.filtersApplied = true;
    }
    if (this.noExpeditedFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoExpedited();
      this.filtersApplied = true;
    }

    setTimeout(() => {
      this.hideLoading();
      this.updateScheduledUnitsSummary();
    }, LOADING_ANIMATION_TIMEOUT);
  }

  updateScheduledUnitsSummary(): void {
    this.clearScheduledUnitsSummary();
    const data = this.getData();

    for (const item of data) {
      if (item.isAccessories) {
        this.addAccessories(item.numberOfParts);
      } else {
        this.addCustomParts(item);
      }
    }
  }

  suggestSchedule(build: boolean = true): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.productionLocationId !== INVALID_LOCATION_ID && this.beginDate.value) {
        this.clearFilters();
        this.clearDataSource();
        this.showLoading();
        this.disableSuggestSchedule();

        this.scheduledUnitsSubscription = this.scheduleService.getScheduledUnits(this.productionLocationId, this.beginDate.value, build)
          .subscribe((scheduledUnits: FormattedScheduledUnit[]) => {
            this.hideLoading();
            this.enableToggleView();
            this.enableSuggestSchedule();
            this.updateScheduledUnitsDataSource(scheduledUnits);
            this.setProductionOrderNumbers();
            this.suggestScheduleExecuted = true;
            resolve();
          },
            err => {
              console.error(err);
              this.hideLoading();
              this.enableSuggestSchedule();
              this.snackBarService.showError('Error getting production schedule.');
              reject();
            });
      } else {
        this.snackBarService.showError('Please select a production location.');
        reject();
      }
    });
  }

  findScheduledUnit(partialUnitId: number): FormattedScheduledUnit {
    return this.scheduledUnitsDataSource.data.find(u => u.partialUnitId === partialUnitId);
  }

  findScheduledUnitsByUniqueUnitId(schedUnit: ScheduledUnit): FormattedScheduledUnit[] {
    return this.scheduledUnitsDataSource.data.filter(c => c.uniqueUnitId === schedUnit.uniqueUnitId);
  }

  findScheduledUnitsByProductionOrderNumber(schedUnit: ScheduledUnit): FormattedScheduledUnit[] {
    return this.scheduledUnitsDataSource.data.filter(c => c.productionOrderNumber === schedUnit.productionOrderNumber);
  }

  updateScheduledUnitsDataSource(scheduledUnits: FormattedScheduledUnit[]) {
    this.scheduledUnitsDataSource.data = scheduledUnits;
  }

  applyFilterByPartialUnitId(partialUnitId: number) {
    this.filteredScheduledUnitsDataSource.data = this.scheduledUnitsDataSource.data;
    this.filteredScheduledUnitsDataSource.data = this.filterByPartialUnitId(partialUnitId);
    this.filtersApplied = true;
  }

  resetFilterByPartialUnitId() {
    this.filtersApplied = false;
    this.filteredScheduledUnitsDataSource.data = [];
  }

  openEditScheduledUnit(partialUnitId: number) {
    const scheduledUnit = this.findScheduledUnit(partialUnitId);
    if (scheduledUnit) {
      const dialogRef = this.dialog.open(EditScheduledUnitComponent, {
        width: '780px',
        data: { scheduledUnit, productionLocations: this.productionLocations }
      });

      dialogRef.afterOpened().subscribe(() => {
        this.applyFilterByPartialUnitId(partialUnitId);
      });

      dialogRef.afterClosed().subscribe((result: FormattedScheduledUnit) => {
        if (result) {
          this.refreshDataSource(scheduledUnit, result);
          this.snackBarService.showSuccess('The scheduled unit information was updated successfully.');
        }
        setTimeout(() => {
          this.resetFilterByPartialUnitId();
          this.applyFilters();
        }, 0);
      });
    }
  }

  resetPONumber() {
    if (this.productionOrderNumber.valid && this.productionOrderNumber.value && this.productionOrderNumber.value.trim()) {
      const productionOrderNumber = this.productionOrderNumber.value.trim();
      const scheduledUnit = this.scheduledUnitsDataSource.data.find(u => u.productionOrderNumber && u.productionOrderNumber.toLowerCase() === productionOrderNumber.toLowerCase());
      if (scheduledUnit) {
        return this.openResetPONumber(scheduledUnit.partialUnitId);
      }
      this.snackBarService.showError(`No production order number found. PO #: ${productionOrderNumber}`);
    }
  }

  openResetPONumber(partialUnitId: number) {
    const scheduledUnit = this.findScheduledUnit(partialUnitId);
    const dialogRef = this.dialog.open(ResetPoNumberComponent, {
      width: '680px',
      disableClose: true,
      data: { scheduledUnit }
    });

    dialogRef.afterClosed().subscribe((scheduledUnits: FormattedScheduledUnit[]) => {
      if (scheduledUnits.length) {
        this.productionOrderNumber.setValue('');
        this.refreshProductionOrders(scheduledUnits);
        this.snackBarService.showSuccess('The PO # was updated successfully.');
      }
    });
  }

  refreshDataSource(prevScheduledUnit: FormattedScheduledUnit, result: FormattedScheduledUnit) {
    const scheduledUnits = this.findScheduledUnitsByUniqueUnitId(result);
    // update scheduled units by unique unit id
    for (const unit of scheduledUnits) {
      unit.isLockedIn = result.isLockedIn;
      unit.isHot = result.isHot;
      unit.isOvernight = result.isOvernight;
      unit.productionLocationId = result.productionLocationId;
      unit.productionLocationStr = result.productionLocationStr;
      unit.orderDate = result.orderDate;
      unit.orderDateStr = result.orderDateStr;
      unit.productionDate = result.productionDate;
      unit.productionDateStr = result.productionDateStr;
      unit.plannedShipDate = result.plannedShipDate;
      unit.plannedShipDateStr = result.plannedShipDateStr;
      unit.requestedShipDate = result.requestedShipDate;
      unit.requestedShipDateStr = result.requestedShipDateStr;
      unit.mustShipDate = result.mustShipDate;
      unit.mustShipDateStr = result.mustShipDateStr;
      unit.isAudit = result.isAudit;
      unit.isAuditStr = result.isAuditStr;
      unit.isRetail = result.isRetail;
      unit.isPalletized = result.isPalletized;
      unit.isPartsOrder = result.isPartsOrder;
      unit.shipMethod = result.shipMethod;
      unit.overnightServiceType = result.overnightServiceType;
    }
    // update scheduled units by PO#
    if (result.productionOrderNumber && result.updateAllPartialUnitsRelated) {
      const relatedPartialUnits = this.findScheduledUnitsByProductionOrderNumber(result);
      for (const unit of relatedPartialUnits) {
        unit.status = result.status;
        unit.friendlyStatus = result.friendlyStatus;
      }
    }
    // update prev
    prevScheduledUnit.sequence = result.sequence;
    prevScheduledUnit.schedulerNotes = result.schedulerNotes;
    prevScheduledUnit.classType = result.classType;
    prevScheduledUnit.status = result.status;
    prevScheduledUnit.friendlyStatus = result.friendlyStatus;
    prevScheduledUnit.isPalletized = result.isPalletized;
    prevScheduledUnit.isPartsOrder = result.isPartsOrder;
    prevScheduledUnit.shipMethod = result.shipMethod;
  }

  refreshProductionOrders(scheduledUnits: FormattedScheduledUnit[]) {
    for (const u of scheduledUnits) {
      const unit = this.findScheduledUnit(u.partialUnitId);
      unit.status = u.status;
      unit.friendlyStatus = u.friendlyStatus;
      unit.productionOrderNumber = u.productionOrderNumber;
      unit.productionStatusColor = u.productionStatusColor;
      unit.moldingLength = u.moldingLength;
      unit.channelGroove = u.channelGroove;
      unit.friendlyMoldingLength = u.friendlyMoldingLength;
      unit.friendlyChannelGroove = u.friendlyChannelGroove;
    }
  }

  openSchedulerNotesDetailsModal(schedulerNotes: string) {
    this.dialog.open(SchedulerNotesDetailsComponent, {
      width: '480px',
      data: { schedulerNotes }
    });
  }

  getScheduleJobStatus() {
    this.scheduleService.getJobStatus(SCHED_JOB).subscribe((jobStatus: JobStatus) => {
      if (jobStatus.state === JobState.running) {
        this.scheduleSyncStartTime = `- ${moment(jobStatus.startTime).format('ddd DD MMM hh:mm A')}`;
        this.monitorScheduleJobStatus();
      }
      this.allowedSynchronizeSchedule = jobStatus.state === JobState.notRunning;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting Sched Job status.');
    });
  }

  monitorScheduleJobStatus() {
    const intervalId = setInterval(() => {
      this.scheduleService.getJobStatus(SCHED_JOB).subscribe((jobStatus: JobStatus) => {
        this.scheduleSyncStartTime = `- ${moment(jobStatus.startTime).format('ddd DD MMM hh:mm A')}`;
        if (jobStatus.state === JobState.notRunning) {
          clearInterval(intervalId);
          this.scheduleSyncStartTime = '';
          this.snackBarService.showSuccess('Sched Job service stopped successfully.');
        }
        this.allowedSynchronizeSchedule = jobStatus.state === JobState.notRunning;
      });
    }, SCHEDULE_JOB_STATUS_INTERVAL);
  }

  clearFilters() {
    this.customerFilter.setValue('');
    this.salesOrderNumberFilter.setValue('');
    this.projectFilter.setValue('');
    this.apartmentFilter.setValue('');
    this.colorFilter.setValue([]);
    this.doorStyleFilter.setValue([]);
    this.customerNumberFilter.setValue('');
    this.productionOrderNumbersFilter.setValue([]);
    this.orderDateFilter.setValue('');
    this.productionDateFilter.setValue('');
    this.requestedShipDateFilter.setValue('');
    this.mustShipDateFilter.setValue('');
    this.productionStatusFilter.setValue([]);
    this.shipMethodFilter.setValue([]);
    this.hotFilter = false;
    this.overnightFilter = false;
    this.multicolorFilter = false;
    this.partsFilter = false;
    this.accessoriesFilter = false;
    this.noAccessoriesFilter = false;
    this.regularFilter = false;
    this.lockedInFilter = false;
    this.noLockedInFilter = false;
    this.sampleFilter = false;
    this.noCapacityFoundFilter = false;
    this.hasPONumberFilter = false;
    this.noHasPONumberFilter = false;
    this.retailFilter = false;
    this.fusionFilter = false;
    this.palletFilter = false;
    this.whatsNotFilter = false;
    this.jitFilter = false;
    this.noJitFilter = false;
    this.auditFilter = false;
    this.expeditedFilter = false;
    this.noExpeditedFilter = false;
    this.filteredScheduledUnitsDataSource.data = [];
    this.clearScheduledUnitsSummary();
    this.filtersApplied = false;
  }

  clearDataSource() {
    this.scheduledUnitsDataSource.data = [];
    this.filteredScheduledUnitsDataSource.data = [];
  }

  clearEditForm() {
    this.isAudit = false;
    this.isLockedIn = false;
    this.isHot = false;
    this.isOvernight = false;
    this.isPalletized = false;
    this.productionDate.setValue('');
    this.productionStatus.setValue('');
    this.productionOrderNumber.setValue('');
    this.schedulerNotes.setValue('');
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  enableSuggestSchedule() {
    this.allowedSuggestSchedule = true;
  }

  disableSuggestSchedule() {
    this.allowedSuggestSchedule = false;
  }

  enableToggleView() {
    this.allowedToggleView = true;
  }

  disableToggleView() {
    this.allowedToggleView = false;
  }

  copyToClipboard(text: string) {
    if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');  // Security exception may be thrown by some browsers.
        this.snackBarService.showSuccess('Text was copied successfully to clipboard.');
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
}
