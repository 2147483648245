<h2 mat-dialog-title>Edit Production Order {{productionOrder.productionOrderNumber}}</h2>
<mat-dialog-content class="mat-dialog-content">
    <div class="group">
        <p><strong>{{productionOrder.productionDate | date:'MMMM d'}}</strong> </p>
        <p><strong>{{productionOrder.friendlyStatus}}</strong></p>
    </div>
    <div class="group">
        <p><strong>{{productionOrder.color}}</strong> </p>
        <p><strong>{{productionOrder.numberOfSheets}}</strong> sheets</p>
    </div>
    <div class="group">
        <p><strong>{{productionOrder.styles}}</strong> </p>
        <p>
            <strong>{{productionOrder.numberOfParts}}</strong> parts
            <span *ngIf="productionOrder.onHold" class="hold">hold</span>
        </p>
    </div>
    <div class="group" *ngIf="productionOrder.isPalletized || productionOrder.isFusion">
        <p *ngIf="productionOrder.isPalletized"><strong>Pallet</strong></p>
        <p *ngIf="productionOrder.isFusion"><strong>Fusion</strong></p>
    </div>
    <div class="group" *ngIf="productionOrder.paperworkPrinted && productionOrder.filesDownloaded">
        <p><strong>{{productionOrder.machine}} - {{productionOrder.dateDownloaded | date:'MMMM d'}} </strong> downloaded
        </p>
    </div>
    <div class="group" *ngIf="productionOrder.gibenStartTime">
        <p>
            <strong>{{productionOrder.gibenStartTime | date:'MMMM d, h:mm a'}}</strong> start time
        </p>
    </div>
    <div class="group" *ngIf="needToDisplayMaterialSupplier(productionOrder)">
        <p>
            <span class="material-supplier" [ngClass]="productionOrder.materialSupplierColor">
                {{productionOrder.materialSupplierStr}}
            </span>
        </p>
    </div>

    <div class="actions">
        <ng-container *ngIf="!routerModeEnabled">
            <button
                *ngIf="productionOrder.paperworkPrinted && productionOrder.filesDownloaded && !productionOrder.gibenStartTime"
                [disabled]="disableUpdateAction" (click)="setGibenStartTime()" mat-raised-button color="accent">
                Start Now
            </button>
        </ng-container>
        <ng-container *ngIf="routerModeEnabled">
            <button [disabled]="!userIsAllowedToRollbackJITScheduling()" mat-raised-button
                (click)="rollbackJITScheduling()" matTooltip="Remove Production Order/ Reject JIT Scheduling Export">
                Reject
            </button>
            <button [disabled]="!userIsAllowedToRollbackJITScheduling()" *ngIf="productionOrder.onHold"
                mat-raised-button (click)="releaseJITScheduling()" matTooltip="Release Production Order">
                Release
            </button>
            <button [disabled]="!userIsAllowedToStartJITScheduling()" mat-raised-button color="accent"
                (click)="startJITScheduling()" matTooltip="Start Just In Time Scheduling">
                Start JIT Scheduling
            </button>
        </ng-container>
    </div>

    <mat-form-field *ngIf="routerModeEnabled" class="full-width">
        <input [disabled]="!userIsAllowedToRollbackJITScheduling()" [(ngModel)]="rejectionNotes" matInput
            placeholder="Rejection Notes" type="text" required />
        <mat-hint>You must include rejection notes.</mat-hint>
        <mat-error>
            Rejection notes is required.
        </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="routerModeEnabled" class="full-width">
        <mat-label>Router</mat-label>
        <mat-select (selectionChange)="onRouterScheduleChange()" [(ngModel)]="routerScheduleId">
            <mat-option *ngFor="let item of routerSchedules" [value]="item.id">
                {{item.routerStr}}
            </mat-option>
        </mat-select>
        <mat-hint>You must have a Router Schedule configured for today in order to change the Router.</mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width">
        <input matInput placeholder="Lot Number" [(ngModel)]="productionOrder.lotNumber" maxlength="150" type="text">
    </mat-form-field>

    <mat-form-field class="full-width">
        <textarea matInput #inputGibenNotes placeholder="Notes" [(ngModel)]="productionOrder.gibenNotes" maxlength="500"
            type="text"></textarea>
        <mat-hint align="end">{{inputGibenNotes.value?.length || 0}}/500</mat-hint>
    </mat-form-field>

    <mat-checkbox *ngIf="userIsAllowedToUnlockProductionOrderFiles()" [(ngModel)]="unlockProductionOrderFiles">
        Unlock production order files
    </mat-checkbox>
    <br />
    <mat-checkbox *ngIf="userIsAllowedToUnlockPrintPaperwork()" [(ngModel)]="unlockPrintPaperwork">
        Unlock print paperwork
    </mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
    <button [disabled]="disableCancelAction" mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button [disabled]="disableUpdateAction" mat-raised-button color="accent"
        (click)="updateProductionOrder()">Update</button>
</mat-dialog-actions>