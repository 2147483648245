<h1 class="mat-h1">
  Final QA
  <div>
    <mat-slide-toggle checked="true" labelPosition="before" (change)="toggleAutoRefresh($event)">
      Auto Refresh
    </mat-slide-toggle>
    <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="Refresh">
      <mat-icon>refresh</mat-icon>
    </button>
    <button *ngIf="!fullScreenMode" mat-mini-fab color="primary" (click)="setFullScreenMode()"
      matTooltip="Fullscreen Mode">
      <mat-icon>fullscreen</mat-icon>
    </button>
    <button *ngIf="fullScreenMode" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
      matTooltip="Exit Fullscreen Mode">
      <mat-icon>fullscreen_exit</mat-icon>
    </button>
  </div>
</h1>
<hr />

<mat-accordion>
  <mat-expansion-panel expanded="true" class="filters-panel">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filters
      </mat-panel-title>
      <mat-panel-description>
        Filter Final QA
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="filters">
      <div class="filters-container">
        <div class="checkboxes">
          <mat-checkbox [(ngModel)]="expeditedFilter" (change)="onExpeditedFilterChange($event)" matTooltip="Expedited">
            Expedited
          </mat-checkbox>
          <mat-checkbox [(ngModel)]="noExpeditedFilter" (change)="onNoExpeditedFilterChange($event)"
            matTooltip="Not Expedited">
            Not Expedited
          </mat-checkbox>
          <mat-checkbox [(ngModel)]="hotFilter" matTooltip="Hot">Hot</mat-checkbox>
          <mat-checkbox [(ngModel)]="fusionFilter" matTooltip="Fusion">Fusion</mat-checkbox>
          <mat-checkbox [(ngModel)]="multicolorFilter" matTooltip="Multi Color">Multi Color</mat-checkbox>
          <mat-checkbox [(ngModel)]="overnightFilter" matTooltip="Overnight">Overnight</mat-checkbox>
        </div>
      </div>

      <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<br />

<div class="reset-action-container" *ngIf="filtersApplied">
  <button mat-raised-button (click)="clearFilters()">
    <mat-icon>clear</mat-icon> Reset Final QA Filters
  </button>
</div>

<div class="container">
  <div class="loading" *ngIf="loading">
    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
  </div>

  <button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
    <mat-icon>arrow_downward</mat-icon>
  </button>

  <button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
    <mat-icon>arrow_upward</mat-icon>
  </button>

  <h3 *ngIf="!loading && scheduledUnitsViewModeActive() && getData().length === 0">
    No scheduled units to show.
  </h3>

  <div class="main-bottom-toolbar">
    <div class="section selection-summary mat-elevation-z2">
      <p>
        <strong>Slab Parts: </strong> <span>{{totalSlabPartsSelected | number}}</span>
      </p>
      <p>
        <strong>Shaker Parts: </strong> <span>{{totalShakerPartsSelected | number}}</span>
      </p>
    </div>

    <div class="section selection-summary mat-elevation-z2">
      <p>
        <strong>Accessories: </strong> <span>{{totalAccessoriesSelected | number}}</span>
      </p>
      <p>
        <strong>Custom Parts: </strong> <span>{{totalCustomPartsSelected | number}}</span>
      </p>
    </div>
  </div>

  <table *ngIf="scheduledUnitsViewModeActive() && getData().length > 0" mat-table
    [dataSource]="getScheduledUnitsDataSource()" #scheduledUnitsSort="matSort" matSort class="mat-elevation-z0 table"
    (matSortChange)="scheduledUnitsSortChange($event)">
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
      attr.sched-unit-id="{{row.partialUnitId}}" [class.is-retail]="row.isRetail" [class.expedited]="row.isExpedited">
    </tr>

    <ng-container matColumnDef="apartmentNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Apt #</th>
      <td mat-cell *matCellDef="let element"> {{element.apartmentNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="salesOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> SO #</th>
      <td mat-cell *matCellDef="let element"> {{element.salesOrderNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="match">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Match</th>
      <td mat-cell *matCellDef="let element"> {{getMatch(element.uniqueUnitId)}} </td>
    </ng-container>

    <ng-container matColumnDef="uniqueUnitId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit #</th>
      <td mat-cell *matCellDef="let element"> {{element.uniqueUnitId}} </td>
    </ng-container>

    <ng-container matColumnDef="partialUnitId">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let element">
        <span class="type hot" *ngIf="element.isHot">H</span>
        <span class="type overnight" *ngIf="element.isOvernight">O</span>
        <span class="type multicolor" *ngIf="element.isMultiColor">M</span>
        <span class="type parts" *ngIf="element.isPartsOrder">P</span>
        <span class="type accessories" *ngIf="element.isAccessories">A</span>
        <span class="type regular" *ngIf="element.isRegularOrder">R</span>
        <span class="type locked-in" *ngIf="element.isLockedIn">L</span>
        <span class="type sample" *ngIf="element.isTestUnit">S</span>
        <span class="type fusion" *ngIf="element.isFusion">F</span>
        <span class="type palletized" *ngIf="element.isPalletized">B</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="productionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Date </th>
      <td mat-cell *matCellDef="let element" class="left-border {{element.classType}}">
        <span class="productionDate {{element.classType}}">{{element.productionDateStr}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Color</th>
      <td mat-cell *matCellDef="let element"> {{element.color}} </td>
    </ng-container>

    <ng-container matColumnDef="style">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style</th>
      <td mat-cell *matCellDef="let element"> {{element.style}} </td>
    </ng-container>

    <ng-container matColumnDef="numberOfParts">
      <th mat-header-cell *matHeaderCellDef> Parts</th>
      <td mat-cell *matCellDef="let element"> &nbsp; {{element.numberOfParts}} &nbsp; </td>
    </ng-container>

    <ng-container matColumnDef="friendlyStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Friendly Status</th>
      <td mat-cell *matCellDef="let element"> {{element.friendlyStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="productionOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PO # </th>
      <td mat-cell *matCellDef="let element">
        <span class="poNumber {{element.productionStatusColor}}">{{element.productionOrderNumber}}</span>
      </td>
    </ng-container>
  </table>
</div>