import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FormattedScheduledUnit } from '../../models/formatted-scheduled-unit';
import { ProductionLocation } from '../../models/production-location';
import { ProductionCalendarService } from '../../services/production-calendar.service';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ScheduleService } from '../../services/schedule.service';
import { SnackbarService } from '../../services/snackbar.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Color } from '../../models/color';
import { DoorStyle } from '../../models/door-style';
import { MatCheckboxChange } from '@angular/material/checkbox';

const INVALID_LOCATION_ID = -1;
const AUTO_REFRESH = 2 * 60 * 1000; // 2 MIN
const STANDARD_SLAB_PART = 'standard slab';
const LOADING_ANIMATION_TIMEOUT = 500; // ms
const SHOW_ALL = 'show-all';

@Component({
  selector: 'app-final-qa',
  templateUrl: './final-qa.component.html',
  styleUrls: ['./final-qa.component.less']
})
export class FinalQaComponent implements OnInit, AfterViewInit, OnDestroy {

  loading = false;
  autoRefresh = true;
  beginDate = new FormControl();
  productionLocations: ProductionLocation[] = [];
  colors: Color[] = [];
  doorStyles: DoorStyle[] = [];
  shipMethods: string[] = [];
  autoRefreshIntervalId = -1;
  productionLocationId = INVALID_LOCATION_ID;
  customPartsCapacity = -1;
  allowedCustomPartsCapacity = -1;
  scheduledUnitsDataSource = new MatTableDataSource<FormattedScheduledUnit>();
  filteredScheduledUnitsDataSource = new MatTableDataSource<FormattedScheduledUnit>();
  scheduledUnitsSort: MatSort;
  lastScheduledUnitsSortState: Sort;
  fullScreenMode = false;
  suggestScheduleExecuted = false;
  allowedSuggestSchedule = false;
  filtersApplied = false;

  finalQA = true;
  viewMode = 'scheduled-units';

  displayedColumns: string[] = ['productionDate', 'match', 'uniqueUnitId', 'productionOrderNumber', 'salesOrderNumber', 'apartmentNumber',
    'color', 'style', 'numberOfParts', 'partialUnitId'];

  hotFilter = false;
  overnightFilter = false;
  multicolorFilter = false;
  fusionFilter = false;
  expeditedFilter = false;
  noExpeditedFilter = false;

  totalCustomPartsSelected: number;
  totalAccessoriesSelected: number;
  totalSlabPartsSelected: number;
  totalShakerPartsSelected: number;

  @ViewChild('scheduledUnitsSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.scheduledUnitsSort = ms;
    this.ngAfterViewInit();

    // trigger sort change
    if (this.scheduledUnitsSort && this.lastScheduledUnitsSortState) {
      setTimeout(() => {
        this.scheduledUnitsSort.active = this.lastScheduledUnitsSortState.active;
        this.scheduledUnitsSort.direction = this.lastScheduledUnitsSortState.direction;
        this.scheduledUnitsSort.sortChange.emit(this.lastScheduledUnitsSortState);
      });
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private scheduleService: ScheduleService,
    private snackBarService: SnackbarService,
    private productionCalendarService: ProductionCalendarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  async ngOnInit() {
    try {
      this.showLoading();
      this.fullScreenMode = this.getFullScreenMode();
      await this.getProductionLocations();
      await this.setBeginDate(new Date());
      await this.suggestSchedule();
      this.setDefaultFilters();
      this.setAutoRefresh();
    } catch (err) {
      console.error('Error loading scheduled units:', err);
    } finally {
      this.hideLoading();
    }
  }

  ngOnDestroy() {
    clearInterval(this.autoRefreshIntervalId);
    this.scheduledUnitsDataSource.disconnect();
    this.scheduledUnitsDataSource.data.length = 0;
  }

  ngAfterViewInit() {
    // sort options
    this.scheduledUnitsDataSource.sort = this.scheduledUnitsSort;
    this.filteredScheduledUnitsDataSource.sort = this.scheduledUnitsSort;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.fullScreenMode = this.getFullScreenMode();
  }

  setDefaultFilters() {
    this.fusionFilter = true;
    this.multicolorFilter = true;
    this.applyFilters();
  }

  setAutoRefresh() {
    // set auto refresh
    this.autoRefreshIntervalId = setInterval(async () => {
      await this.refresh();
    }, AUTO_REFRESH);
  }

  async refresh() {
    if (this.scheduledUnitsViewModeActive()) {
      await this.suggestSchedule();
    }
    this.applyFilters();
  }

  scheduledUnitsViewModeActive() {
    return !this.loading && this.suggestScheduleExecuted && this.viewMode === 'scheduled-units';
  }

  async suggestSchedule(build: boolean = false): Promise<void> {
    if (this.productionLocationId !== INVALID_LOCATION_ID && this.beginDate.value) {
      try {
        this.clearDataSource();
        this.showLoading();
        this.disableSuggestSchedule();
        const scheduledUnits = await this.scheduleService.getScheduledUnits(this.productionLocationId, this.beginDate.value, build, this.finalQA)
          .toPromise();
        this.updateScheduledUnitsDataSource(scheduledUnits);
        this.suggestScheduleExecuted = true;
      } catch (err) {
        console.error(err);
        this.snackBarService.showError('Error getting production schedule.');
      } finally {
        this.hideLoading();
        this.enableSuggestSchedule();
      }
    } else {
      this.snackBarService.showError('Please select a production location.');
    }
  }

  clearDataSource() {
    this.scheduledUnitsDataSource.data = [];
    this.filteredScheduledUnitsDataSource.data = [];
  }

  getScheduledUnitsDataSource(): MatTableDataSource<FormattedScheduledUnit> {
    return this.filtersApplied ? this.filteredScheduledUnitsDataSource : this.scheduledUnitsDataSource;
  }

  getData(): FormattedScheduledUnit[] {
    return this.getScheduledUnitsDataSource().connect().value;
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  updateScheduledUnitsDataSource(scheduledUnits: FormattedScheduledUnit[]) {
    this.scheduledUnitsDataSource.data = scheduledUnits;
  }

  enableSuggestSchedule() {
    this.allowedSuggestSchedule = true;
  }

  disableSuggestSchedule() {
    this.allowedSuggestSchedule = false;
  }

  scheduledUnitsSortChange(e: Sort) {
    this.lastScheduledUnitsSortState = e;
  }

  async setBeginDate(date: Date) {
    try {
      const productionCalendar = await this.productionCalendarService.getProductionCalendarBy(this.productionLocationId, date).toPromise();
      this.customPartsCapacity = productionCalendar.customPartsCapacity;
      this.allowedCustomPartsCapacity = productionCalendar.allowedCustomPartsCapacity;
    } catch (err) {
      console.error('Error getting production calendar:', err);
      this.customPartsCapacity = -1;
      this.allowedCustomPartsCapacity = -1;
    } finally {
      this.beginDate.setValue(date);
    }
  }

  getMatch(uniqueUnitId: number): string {
    const uniqueUnitIdStr = uniqueUnitId.toString();
    return uniqueUnitIdStr.slice(-4);
  }

  applyFilters() {
    this.showLoading();

    this.filteredScheduledUnitsDataSource.data = this.scheduledUnitsDataSource.data;

    if (this.hotFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByHot();
      this.filtersApplied = true;
    }
    if (this.overnightFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByOvernight();
      this.filtersApplied = true;
    }
    if (this.fusionFilter && !this.multicolorFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByFusion();
      this.filtersApplied = true;
    }
    if (this.multicolorFilter && !this.fusionFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByMultiColors();
      this.filtersApplied = true;
    }
    if (this.fusionFilter && this.multicolorFilter) {
      this.filteredScheduledUnitsDataSource.data = [...this.filterByFusion(), ...this.filterByMultiColors()];
      this.filtersApplied = true;
    }
    if (this.expeditedFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByExpedited()
      this.filtersApplied = true;
    }
    if (this.noExpeditedFilter) {
      this.filteredScheduledUnitsDataSource.data = this.filterByNoExpedited()
      this.filtersApplied = true;
    }
    setTimeout(() => {
      this.hideLoading();
      this.updateScheduledUnitsSummary();
    }, LOADING_ANIMATION_TIMEOUT);
  }

  updateScheduledUnitsSummary(): void {
    this.clearScheduledUnitsSummary();
    const data = this.getData();

    for (const item of data) {
      if (item.isAccessories) {
        this.addAccessories(item.numberOfParts);
      } else {
        this.addCustomParts(item);
      }
    }
  }

  isScheduledUnit(item: FormattedScheduledUnit): item is FormattedScheduledUnit {
    return (item as FormattedScheduledUnit).color !== undefined;
  }

  isSlabPart(item: FormattedScheduledUnit): boolean {
    return this.isScheduledUnit(item) && item.style.toLowerCase() === STANDARD_SLAB_PART;
  }

  isShakerPart(item: FormattedScheduledUnit): boolean {
    return this.isScheduledUnit(item) && item.style.toLowerCase() !== '' && item.style.toLowerCase() !== STANDARD_SLAB_PART;
  }

  addCustomParts(item: FormattedScheduledUnit) {
    this.totalSlabPartsSelected += this.isSlabPart(item) ? item.numberOfParts : 0;
    this.totalShakerPartsSelected += this.isShakerPart(item) ? item.numberOfParts : 0;
    this.totalCustomPartsSelected += item.numberOfParts;
  }

  addAccessories(totalAccessories: number) {
    this.totalAccessoriesSelected += totalAccessories;
  }

  filterByHot(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isHot);
  }

  filterByOvernight(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isOvernight);
  }

  filterByFusion(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isFusion);
  }

  filterByMultiColors(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isMultiColor);
  }

  onMultipleSelectionChange(e: MatSelectChange) {
    for (const value of e.value) {
      if (value === SHOW_ALL) {
        e.source.value = [SHOW_ALL];
        break;
      }
    }
  }

  filterByExpedited(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => u.isExpedited);
  }

  filterByNoExpedited(): FormattedScheduledUnit[] {
    return this.filteredScheduledUnitsDataSource.data.filter(u => !u.isExpedited);
  }

  async onProductionLocationChange(event: MatSelectChange) {
    clearInterval(this.autoRefreshIntervalId);
    this.productionLocationId = event.value;
    await this.setBeginDate(new Date());
    await this.refresh();
    if (this.autoRefresh) {
      this.setAutoRefresh();
    }
  }



  onExpeditedFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.noExpeditedFilter) {
      this.noExpeditedFilter = false;
    }
  }

  onNoExpeditedFilterChange(e: MatCheckboxChange) {
    if (e.checked && this.expeditedFilter) {
      this.expeditedFilter = false;
    }
  }

  clearFilters() {
    this.hotFilter = false;
    this.overnightFilter = false;
    this.multicolorFilter = false;
    this.fusionFilter = false;
    this.expeditedFilter = false;
    this.noExpeditedFilter = false;
    this.filteredScheduledUnitsDataSource.data = [];
    this.filtersApplied = false;
    this.updateScheduledUnitsSummary();
  }

  clearScheduledUnitsSummary() {
    this.totalCustomPartsSelected = 0;
    this.totalAccessoriesSelected = 0;
    this.totalSlabPartsSelected = 0;
    this.totalShakerPartsSelected = 0;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }
  getFullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  toggleAutoRefresh(e: MatSlideToggleChange) {
    clearInterval(this.autoRefreshIntervalId);
    this.autoRefresh = e.checked;
    if (this.autoRefresh) {
      this.setAutoRefresh();
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        const defaultLocation = productionLocations.find(p => p.isDefault);
        this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }
}
