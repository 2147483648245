import { Component, OnInit } from '@angular/core';
import { ExpeditedProductionSetting } from 'src/app/models/expedited-production-setting';
import { ProductionMoldingSetting } from 'src/app/models/production-molding-setting';
import { StandardItemShipInSetup } from 'src/app/models/standard-item-ship-in-setup';
import { StrategicExpedited } from 'src/app/models/strategic-expedited';
import { SettingsService } from 'src/app/services/settings.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ShippingItemSetupService } from 'src/app/services/shipping-item-setup.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit {

  groupNames: string[] = [];
  productionMoldingSettings: ProductionMoldingSetting[] = [];
  expeditedProductionSettings: ExpeditedProductionSetting[] = [];
  standardItemShipInSetup: StandardItemShipInSetup[] = [];
  strategicExpedited: StrategicExpedited[] = [];

  displayedColumns: string[] = ['id', 'color', 'doorStyle', 'moldingLength', 'friendlyMoldingLength', 'channelGroove', 'friendlyChannelGroove', 'isDefault', 'actions'];
  expeditedDisplayedColumns: string[] = ['id', 'productionType', 'feePerPart', 'minimumFee', 'description', 'isActive', 'dateModified', 'actions'];
  standardItemShipInSetupDisplayedColumns: string[] = ['id', 'itemNumber', 'cartonTypeId', 'categoryTypeId', 'dateCreated', 'createdBy', 'actions'];
  strategicExpeditedSettingsDisplayedColumns: string[] = ['transitDays', 'state', 'isActive','actions']

  constructor(
    private settingsService: SettingsService,
    private shippingSetupService: ShippingItemSetupService,
    private snackBarService: SnackbarService) { }

  ngOnInit() {
    this.getGroupNames();
    this.getProductionMoldingSettings();
    this.getExpeditedProductionSettings();
    this.getStandardItemShipInSetup();
    this.getStrategicExpeditedSettings();
  }

  getGroupNames() {
    this.settingsService.getGroupNames().subscribe((groupNames: string[]) => {
      this.groupNames = groupNames;
    },
      err => {
        console.error(err);
        this.snackBarService.showError('Error getting global settings.');
      });
  }

  getProductionMoldingSettings() {
    this.settingsService.getProductionMoldingSettings().subscribe((productionMoldingSettings: ProductionMoldingSetting[]) => {
      this.productionMoldingSettings = productionMoldingSettings;
    }, err => {
      console.error('Error getting production molding settings.', err);
      this.snackBarService.showError('Error getting production molding settings.');
    });
  }

  getExpeditedProductionSettings() {
    this.settingsService.getExpeditedProductionSetting().subscribe((expeditedProductionSettings: ExpeditedProductionSetting[]) => {
      this.expeditedProductionSettings = expeditedProductionSettings;
    }, err => {
      console.error('Error getting expedited production settings.', err);
      this.snackBarService.showError('Error getting expedited production settings.');
    });
  }

  getStandardItemShipInSetup(){
    this.shippingSetupService.getStandardItemShipInSetup().subscribe((standardItemShipInSetup: StandardItemShipInSetup[]) => {
      this.standardItemShipInSetup = standardItemShipInSetup;
    }, err => {
      console.error('Error getting standard item ship in settings.', err);
      this.snackBarService.showError('Error getting standard item ship in settings.');
    });
  }

  getStrategicExpeditedSettings(){
    this.settingsService.getStrategicExpedited().subscribe((strategicExpeditedSettings: StrategicExpedited[]) =>{
      this.strategicExpedited = strategicExpeditedSettings;
    }, error => {
      this.snackBarService.showError('Error getting Strategic Expedited Settings');
    })
  }

  formatPercentage(value: number): string {
    return `${(value * 1).toFixed(2)}%`;
  }
}


