import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
  BottomSheetScheduleLegendComponent,
} from '../shared/bottom-sheet-schedule-legend/bottom-sheet-schedule-legend.component';
import { ProductionStatus } from '../../models/production-status';
import { ProductionService } from '../../services/production.service';
import { ProductionLocation } from '../../models/production-location';
import { ProductionPartsNotCartoned } from '../../models/production-parts-not-cartoned';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { SnackbarService } from '../../services/snackbar.service';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ProductionStatusesService } from '../../services/production-statuses.service';
import { Color } from '../../models/color';
import { DoorStyle } from '../../models/door-style';
import { ProductionCalendarService } from '../../services/production-calendar.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ScheduleService } from '../../services/schedule.service';
import { Subscription } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

const INVALID_LOCATION_ID = -1;
const LOADING_ANIMATION_TIMEOUT = 500; // ms
const AUTO_REFRESH = 60 * 1000; // 1 MIN
const SHOW_ALL = 'show-all';
const STANDARD_SLAB_PART = 'standard slab';


@Component({
  selector: 'app-whats-not-scheduled',
  templateUrl: './whats-not-scheduled.component.html',
  styleUrls: ['./whats-not-scheduled.component.less']
})
export class WhatsNotScheduledComponent implements OnInit, AfterViewInit, OnDestroy {

  loading = false;
  fullScreenMode = false;
  filtersApplied = false;
  autoRefreshIntervalId = -1;
  productionLocationId = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];
  readonly startPanelsCutStatus = ProductionStatus.StartPanelsCut;
  productionStatusKeys: string[] = [];
  productionStatuses: Map<string, string> = new Map<string, string>();
  colors: Color[] = [];
  doorStyles: DoorStyle[] = [];
  shipMethods: string[] = [];
  productionOrderNumbers = [];

  customerFilter = new FormControl();
  salesOrderNumberFilter = new FormControl();
  productionDateFilter = new FormControl();
  productionOrderNumbersFilter = new FormControl([]);
  colorFilter = new FormControl([]);
  doorStyleFilter = new FormControl([]);
  shipMethodFilter = new FormControl([]);
  communityFilter = new FormControl();
  apartmentFilter = new FormControl();
  productionStatusFilter = new FormControl([]);
  accessoriesOnlyFilter = new FormControl(false);
  totalCustomPartsSelected = 0;
  totalSlabPartsSelected = 0;
  totalShakerPartsSelected = 0;
  totalAccessoriesSelected = 0;

  displayedColumns: string[] = ['productionDate', 'lastStatusRecorded', 'type', 'elapsedBusinessDays', 'dateOrdered', 'requestedDeliveryDate', 'mustShipDate', 'plannedShipDate', 'color', 'style', 'totalParts', 'productionLocationStr', 'salesOrderNumber', 'customerName',
    'customerNumber', 'community', 'apartmentNumber', 'isLockedIn', 'expedited', 'isHot', 'isMultiColorUnit', 'isFusion', 'isPalletized', 'isAccessoryOnly', 'transitDays', 'fedexTransitDays', 'isAudit', 'shipMethod', 'schedulerNotes'];

  productionPartsNotCartonedSubscription: Subscription;
  productionPartsNotCartonedDataSource = new MatTableDataSource<ProductionPartsNotCartoned>();
  filteredProductionPartsNotCartonedDataSource = new MatTableDataSource<ProductionPartsNotCartoned>();

  lastSortState: Sort;
  productionOrdersSort: MatSort;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private bottomSheetScheduleLegend: MatBottomSheet,
    private snackBarService: SnackbarService,
    private scheduleService: ScheduleService,
    private productionService: ProductionService,
    private productionStatusesService: ProductionStatusesService,
    private productionCalendarService: ProductionCalendarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  @ViewChild('productionOrdersSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.productionOrdersSort = ms;
    this.ngAfterViewInit();

    // trigger sort change
    if (this.productionOrdersSort && this.lastSortState) {
      setTimeout(() => {
        this.productionOrdersSort.active = this.lastSortState.active;
        this.productionOrdersSort.direction = this.lastSortState.direction;
        this.productionOrdersSort.sortChange.emit(this.lastSortState);
      });
    }
  }

  async ngOnInit() {
    this.getColors();
    this.getDoorStyles();
    this.getShipMethods();
    this.getProductionStatuses();
    this.getProductionLocations();
    this.getProductionPartsNotCartoned();
    this.setAutoRefresh();
    this.fullScreenMode = this.getFullScreenMode();
  }

  sortChange(e: Sort) {
    this.lastSortState = e;
  }

  setAutoRefresh() {
    // set auto refresh
    this.autoRefreshIntervalId = setInterval(() => {
      this.refresh();
    }, AUTO_REFRESH);
  }

  ngOnDestroy() {
    clearInterval(this.autoRefreshIntervalId);
    // clear production orders data and subscription
    if (this.productionPartsNotCartonedSubscription) {
      this.productionPartsNotCartonedSubscription.unsubscribe();
    }
    this.productionPartsNotCartonedDataSource.disconnect();
    this.productionPartsNotCartonedDataSource.data.length = 0;
  }

  toggleAutoRefresh(e: MatSlideToggleChange) {
    clearInterval(this.autoRefreshIntervalId);
    if (e.checked) {
      this.setAutoRefresh();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.fullScreenMode = this.getFullScreenMode();
  }

  openBottomSheetScheduleLegend() {
    this.bottomSheetScheduleLegend.open(BottomSheetScheduleLegendComponent);
  }

  getFullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  ngAfterViewInit() {
    // sort options
    this.productionPartsNotCartonedDataSource.sort = this.productionOrdersSort;
    this.filteredProductionPartsNotCartonedDataSource.sort = this.productionOrdersSort;
  }

  onMultipleSelectionChange(e: MatSelectChange) {
    for (const value of e.value) {
      if (value === SHOW_ALL) {
        e.source.value = [SHOW_ALL];
        break;
      }
    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  async refresh() {
    await this.getProductionPartsNotCartoned();
    this.applyFilters();
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  getProductionOrderNumberClassType(p: ProductionPartsNotCartoned): string {
    return p.productionOrderNumber && this.getValueOfProductionStatus(p.lastStatusRecorded) < this.startPanelsCutStatus ? 'red' : 'green';
  }

  getValueOfProductionStatus(status: string): number {
    for (const value in ProductionStatus) {
      if (isNaN(Number(value)) && value.toUpperCase() === status.toUpperCase()) {
        return ProductionStatus[status];
      }
    }
  }

  needToDisplayMaterialSupplier(e: ProductionPartsNotCartoned): boolean {
    const colors: string[] = ['Flour', 'Mist'];
    return e.materialSupplierColor && colors.indexOf(e.color) !== -1;
  }

  getData(): ProductionPartsNotCartoned[] {
    return this.getProductionPartsNotCartonedDataSource().connect().value;
  }

  getProductionPartsNotCartonedDataSource(): MatTableDataSource<ProductionPartsNotCartoned> {
    return this.filtersApplied ? this.filteredProductionPartsNotCartonedDataSource : this.productionPartsNotCartonedDataSource;
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }

  getProductionPartsNotCartoned(): Promise<void> {
    return this.getProductionOrdersNotCartoned();
  }

  getFriendlyType(type: string): string {
    const friendlyType = { 'past-due-critical': 'Critical', 'past-due': 'Past Due', 'warning': 'Warning', 'accessory-only': 'Accessory Only', 'upcoming': 'Upcoming' };
    return friendlyType[type];
  }

  getProductionOrdersNotCartoned(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.showLoading();
      if (this.productionPartsNotCartonedSubscription) {
        this.productionPartsNotCartonedSubscription.unsubscribe();
      }
      this.productionPartsNotCartonedSubscription = this.productionService.getWhatsNotScheduledByDate().subscribe((productionParts: ProductionPartsNotCartoned[]) => {
        this.productionPartsNotCartonedDataSource.data = productionParts;
        this.setProductionOrderNumbers();
        this.updateWhatsNotSummary();
        this.hideLoading();
        resolve();
      }, err => {
        console.error('Error getting production orders not cartoned:', err);
        this.snackBarService.showError('Error getting production orders not cartoned.');
        this.hideLoading();
        reject();
      });
    });
  }

  getColors() {
    this.productionCalendarService.getColors().subscribe((colors: Color[]) => {
      this.colors = colors;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    });
  }

  getDoorStyles() {
    this.productionCalendarService.getDoorStyles().subscribe((doorStyles: DoorStyle[]) => {
      this.doorStyles = doorStyles;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting door styles.');
    });
  }

  getShipMethods() {
    this.scheduleService.getShipMethods().subscribe((shipMethods: string[]) => {
      this.shipMethods = shipMethods;
    }, err => {
      console.error(err);
      this.snackBarService.showError('Error getting ship methods.');
    });
  }

  async getProductionStatuses() {
    const statuses = await this.productionStatusesService.getProductionStatuses().toPromise();
    for (const status of Object.keys(statuses)) {
      this.productionStatuses.set(status, statuses[status]);
      this.productionStatusKeys.push(status);
    }
  }

  setProductionOrderNumbers() {
    const productionOrderNumbers = new Set();
    this.productionPartsNotCartonedDataSource.data
      .filter(p => p.productionOrderNumber)
      .forEach(p => {
        productionOrderNumbers.add(p.productionOrderNumber);
      });
    this.productionOrderNumbers = Array.from(productionOrderNumbers).map(p => {
      return { value: p };
    });
  }

  applyFilters(): void {
    this.showLoading();
    this.filteredProductionPartsNotCartonedDataSource.data = this.productionPartsNotCartonedDataSource.data;

    if (this.productionDateFilter.valid && this.productionDateFilter.value) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByProductionDate();
      this.filtersApplied = true;
    }
    if (this.productionStatusFilter.valid && this.productionStatusFilter.value.length > 0) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByProductionStatus();
      this.filtersApplied = true;
    }
    if (this.colorFilter.valid && this.colorFilter.value.length > 0) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByColor();
      this.filtersApplied = true;
    }
    if (this.doorStyleFilter.valid && this.doorStyleFilter.value.length > 0) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByDoorStyle();
      this.filtersApplied = true;
    }
    if (this.salesOrderNumberFilter.valid && this.salesOrderNumberFilter.value) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterBySONumber();
      this.filtersApplied = true;
    }
    if (this.productionOrderNumbersFilter.valid && this.productionOrderNumbersFilter.value.length > 0) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByPONumbers();
      this.filtersApplied = true;
    }
    if (this.productionLocationId !== INVALID_LOCATION_ID) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByProductionLocation();
      this.filtersApplied = true;
    }
    if (this.customerFilter.valid && this.customerFilter.value) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByCustomer();
      this.filtersApplied = true;
    }
    if (this.communityFilter.valid && this.communityFilter.value) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByCommunity();
      this.filtersApplied = true;
    }
    if (this.apartmentFilter.valid && this.apartmentFilter.value) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByApartment();
      this.filtersApplied = true;
    }
    if (this.shipMethodFilter.valid && this.shipMethodFilter.value.length > 0) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByShipMethod();
      this.filtersApplied = true;
    }
    if (this.accessoriesOnlyFilter.valid && this.accessoriesOnlyFilter.value) {
      this.filteredProductionPartsNotCartonedDataSource.data = this.filterByIsAccessoriesOnly();
      this.filtersApplied = true;
    }
    setTimeout(() => {
      this.hideLoading();
      this.updateWhatsNotSummary();
    }, LOADING_ANIMATION_TIMEOUT);
  }

  updateWhatsNotSummary(): void {
    this.clearWhatsNotSummary();
    const data = this.getData();

    for (const item of data) {
      if (this.isAccessories(item)) {
        this.addAccessories(item);
      } else {
        this.addCustomParts(item);
      }
    }
  }

  addAccessories(item: ProductionPartsNotCartoned) {
    this.totalAccessoriesSelected += item.totalParts;
  }

  addCustomParts(item: ProductionPartsNotCartoned) {
    this.totalSlabPartsSelected += this.isSlabPart(item) ? item.totalParts : 0;
    this.totalShakerPartsSelected += this.isShakerPart(item) ? item.totalParts : 0;
    this.totalCustomPartsSelected += item.totalParts;
  }

  isAccessories(item: ProductionPartsNotCartoned) {
    return item.color === '' && item.style === '';
  }

  isSlabPart(item: ProductionPartsNotCartoned): boolean {
    return !this.isAccessories(item) && item.style.toLowerCase() === STANDARD_SLAB_PART;
  }

  isShakerPart(item: ProductionPartsNotCartoned): boolean {
    return !this.isAccessories(item) && item.style.toLowerCase() !== STANDARD_SLAB_PART;
  }

  clearWhatsNotSummary() {
    this.totalCustomPartsSelected = 0;
    this.totalAccessoriesSelected = 0;
    this.totalSlabPartsSelected = 0;
    this.totalShakerPartsSelected = 0;
  }

  filterByIsAccessoriesOnly() {
    return this.filteredProductionPartsNotCartonedDataSource.data.filter(item => item.isAccessoryOnly);
  }

  filterByProductionDate() {
    return this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.productionDate && this.equals(this.productionDateFilter.value, u.productionDate));
  }

  filterByProductionStatus() {
    const result = [];
    const productionStatuses = this.productionStatusFilter.value.filter(c => c)
      .map(c => c.trim().toLowerCase());
    for (const status of productionStatuses) {
      if (status === SHOW_ALL) {
        return this.filteredProductionPartsNotCartonedDataSource.data;
      }
      const data = this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.lastStatusRecorded.trim().toLowerCase() === status);
      result.push(...data);
    }
    return result;
  }

  filterByColor() {
    const result = [];
    const colors = this.colorFilter.value.filter(c => c);
    for (const color of colors) {
      if (color === SHOW_ALL) {
        return this.filteredProductionPartsNotCartonedDataSource.data;
      }
      const data = this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.color === color);
      result.push(...data);
    }
    return result;
  }

  filterByDoorStyle() {
    const result = [];
    const doorStyles = this.doorStyleFilter.value.filter(c => c);
    for (const doorStyle of doorStyles) {
      if (doorStyle === SHOW_ALL) {
        return this.filteredProductionPartsNotCartonedDataSource.data;
      }
      const data = this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.style === doorStyle);
      result.push(...data);
    }
    return result;
  }

  filterByShipMethod() {
    const result = [];
    const methods = this.shipMethodFilter.value.filter(c => c);
    for (const method of methods) {
      if (method === SHOW_ALL) {
        return this.filteredProductionPartsNotCartonedDataSource.data;
      }
      const data = this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.shipMethod === method);
      result.push(...data);
    }
    return result;
  }

  filterBySONumber() {
    return this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.salesOrderNumber.toLowerCase()
      .includes(this.salesOrderNumberFilter.value.toLowerCase()));
  }

  filterByPONumbers() {
    const result = [];
    const poNumbers = this.productionOrderNumbersFilter.value.map(p => p.toLowerCase());
    for (const poNumber of poNumbers) {
      const data = this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.productionOrderNumber && u.productionOrderNumber.toLowerCase().includes(poNumber));
      result.push(...data);
    }
    return result;
  }

  filterByProductionLocation() {
    return this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.productionLocationId === this.productionLocationId);
  }

  filterByCustomer() {
    return this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.customerName.toLowerCase().includes(this.customerFilter.value.toLowerCase()));
  }

  filterByCommunity() {
    return this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.community.toLowerCase().includes(this.communityFilter.value.toLowerCase()));
  }

  filterByApartment() {
    return this.filteredProductionPartsNotCartonedDataSource.data.filter(u => u.apartmentNumber.toLowerCase().includes(this.apartmentFilter.value.toLowerCase()));
  }

  equals(date1: string | Date, date2: string | Date): boolean {
    const a = new Date(date1);
    const b = new Date(date2);
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }

  clearFilters() {
    this.productionDateFilter.setValue('');
    this.colorFilter.setValue([]);
    this.doorStyleFilter.setValue([]);
    this.salesOrderNumberFilter.setValue('');
    this.productionOrderNumbersFilter.setValue([]);
    this.shipMethodFilter.setValue([]);
    this.customerFilter.setValue('');
    this.communityFilter.setValue('');
    this.apartmentFilter.setValue('');
    this.accessoriesOnlyFilter.setValue(false);
    this.productionStatusFilter.setValue([]);
    this.productionLocationId = INVALID_LOCATION_ID;
    this.filteredProductionPartsNotCartonedDataSource.data = [];
    this.filtersApplied = false;
    this.updateWhatsNotSummary();
  }

  copyToClipboard(text: string | number) {
    if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = `${text}`;
      textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');  // Security exception may be thrown by some browsers.
        this.snackBarService.showSuccess('Text was copied successfully to clipboard.');
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

}
