<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Edit Router Setup
    </h1>
    <hr />

    <div class="container">
        <form [formGroup]="routerSetupForm" (ngSubmit)="submit()">
            <mat-form-field class="full-width">
                <mat-label>Router Schedule</mat-label>
                <mat-select formControlName="routerScheduleId" (selectionChange)="onRouterSchedulesChange($event)"
                    required>
                    <mat-option *ngFor="let item of routerSchedules" [value]="item.id">
                        {{formatRouterSchedule(item)}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="routerSetupForm.get('routerScheduleId').hasError('required')">
                    Router Schedule is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Colors</mat-label>
                <mat-select formControlName="colors" multiple required>
                    <mat-option *ngFor="let item of colors" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-select>
                <mat-hint align="start">Select 1 or multiples colors.</mat-hint>
                <mat-error *ngIf="routerSetupForm.get('colors').hasError('required')">
                    Colors is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Styles</mat-label>
                <mat-select formControlName="styles" multiple required>
                    <mat-option *ngFor="let item of doorStyles" [value]="item">
                        {{item.name}}
                    </mat-option>
                </mat-select>
                <mat-hint align="start">Select 1 or multiples styles.</mat-hint>
                <mat-error *ngIf="routerSetupForm.get('styles').hasError('required')">
                    Styles is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input matInput type="number" placeholder="Max Parts In Batch" formControlName="maxPartsInBatch"
                    required>
                <mat-error *ngIf="routerSetupForm.get('maxPartsInBatch').hasError('required')">
                    Max Parts In Batch is required
                </mat-error>
                <mat-error *ngIf="routerSetupForm.get('maxPartsInBatch').hasError('min')">
                    Min value 1 required
                </mat-error>
                <mat-error *ngIf="routerSetupForm.get('maxPartsInBatch').hasError('max')">
                    Max value 99999 required
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="accent">Update</button>
        </form>
    </div>
</mat-card>

<div class="loading" *ngIf="loading">
    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
</div>

<div class="row" *ngIf="!loading && productionOrderStats">
    <div *ngIf="productionOrderStats.totalCustomPartsExported >= productionOrderStats.customPartsCapacity"
        class="alert">
        <p>
            Total Parts Exported equal or exceed the Custom Part Capacity. To generate new
            production orders you must increase the Allowed Custom Part Capacity on the production
            calendar. (EN)
        </p>
        <p>
            El total de piezas exportadas iguala o supera la capacidad de piezas permitidas. Para
            generar nuevas
            órdenes de producción se debe aumentar la capacidad de piezas permitidas en el calendario de
            producción. (ES)
        </p>
    </div>
    <p>
        <strong>Minimum Batch:</strong> {{productionOrderStats.minimumPanelsRequired}}
        <strong>Small Batch:</strong> {{productionOrderStats.smallBatchPanelsRequired}}
        <strong>Warming Up Batch:</strong> {{productionOrderStats.warmingUpPanelsRequired}}
        <strong>Custom Parts Exported:</strong> {{productionOrderStats.customPartsExported}}
        <strong>Custom Parts Capacity:</strong> {{productionOrderStats.customPartsCapacity}}
        <strong>Custom Parts Behind:</strong> {{productionOrderStats.customPartsBehind}}
        <strong>Custom Slab Parts Available:</strong> {{productionOrderStats.customSlabPartsAvailable}}
        <strong>Custom Parts Available:</strong> {{productionOrderStats.customPartsAvailable}}
        <strong>Custom Parts Backlog:</strong> {{productionOrderStats.customPartsNotExported}}
    </p>
    <p class="customPartsBehind warning">
        <strong>Custom Parts Behind Alert.</strong>
        <ng-container *ngFor="let item of productionOrderStats.customPartsBehindByStyle">
            <strong>{{item.style}}:</strong> {{item.total}}
        </ng-container>
        <strong>Parts Orders Non Exported:</strong>
        {{productionOrderStats.partsOrdersNonExported}}
        <strong>Custom Parts LockedIn Non Exported:</strong>
        {{productionOrderStats.customPartsLockedInNonExported}}
        <strong>Custom Slab Parts LockedIn Non Exported:</strong>
        {{productionOrderStats.customSlabPartsLockedInNonExported}}
    </p>
    <div class="stats">
        <div class="stat" *ngFor="let item of productionOrderStats.routerSetupStats"
            [class.alert]="item.mustBeExported">
            <p><strong>Color:</strong> {{item.color}}</p>
            <p><strong>Style:</strong> {{item.style}}</p>
            <p><strong>Min Stack Rank:</strong> {{item.minStackRank}}</p>
            <p><strong>Min Date Ordered:</strong> {{item.minDateOrdered | date}}</p>
            <p><strong>Custom Parts Behind:</strong> {{item.customPartsBehind}} </p>
            <p><strong>Custom Parts Available:</strong> {{item.customPartsAvailable}} </p>
        </div>
    </div>
</div>