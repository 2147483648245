import { Component, OnInit, Inject, HostListener, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { EditProductionOrderComponent } from '../production-orders/edit-production-order/edit-production-order.component';
import { ProductionOrdersService } from '../../services/production-orders.service';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ProductionOrder } from '../../models/production-order';
import { ProductionOrderStats } from '../../models/production-order-stats';
import { ProductionLocation } from '../../models/production-location';
import { AuthService } from '../../services/auth.service';
import { ProductionCalendarService } from '../../services/production-calendar.service';
import { ScheduleService } from '../../services/schedule.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Color } from '../../models/color';
import { ProductionStatus } from '../../models/production-status';
import { RoutersService } from '../../services/routers.service';
import { Subscription } from 'rxjs';
import { UserRole } from '../../models/user-role';

const INVALID_LOCATION_ID = -1;
const AUTO_REFRESH = 2 * 60 * 1000; // 2 MIN
const ALLOWED_ROLES = [UserRole.Admin, UserRole.ProductionManager];

@Component({
  selector: 'app-table-saw',
  templateUrl: './table-saw.component.html',
  styleUrls: ['./table-saw.component.less']
})
export class TableSawComponent implements OnInit, AfterViewInit, OnDestroy {

  loading = false;
  filtersApplied = false;
  fullScreenMode = false;
  productionManagerModeEnabled = false;
  machine = 'No IP address to show.';
  errorGettingMachine = false;
  autoRefreshIntervalId: number = -1;
  poNumberFilter = new FormControl();
  productionDateFilter = new FormControl();
  colorsFilter = new FormControl();

  colors: Color[] = [];
  productionOrderStats: ProductionOrderStats;
  productionLocationId = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];

  displayedColumns: string[] = ['productionDate', 'productionOrderNumber', 'color', 'styles', 'materialSupplier', 'numberOfParts', 'fusion', 'wip', 'notes', 'status', 'print', 'action'];

  productionOrdersSubscription: Subscription;
  productionOrdersDataSource = new MatTableDataSource<ProductionOrder>();
  filteredProductionOrdersDataSource = new MatTableDataSource<ProductionOrder>();
  productionOrdersSort: MatSort;

  @ViewChild('productionOrdersSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.productionOrdersSort = ms;
    this.ngAfterViewInit();
  }

  constructor(
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    private routersService: RoutersService,
    private snackBarService: SnackbarService,
    private scheduleService: ScheduleService,
    private productionOrdersService: ProductionOrdersService,
    private productionCalendarService: ProductionCalendarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  async ngOnInit() {
    this.showLoading();
    try {
      await this.setDefaultValues();
      await this.getProductionOrders();

      this.applyFilters();

      this.autoRefreshIntervalId = setInterval(() => {
        this.refresh().catch(err => {
          console.error(err);
          this.snackBarService.showError('Error refreshing the Giben information. Please reload the page.');
        });
      }, AUTO_REFRESH);
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting the Giben information. Please reload the page.');
    }

    this.hideLoading();
  }

  async setDefaultValues() {
    this.enableProductionManagerMode();
    this.setIsDefaultFullScreenMode();
    await this.getColors();
    await this.getProductionLocations();
    this.setMachine();
  }

  ngAfterViewInit() {
    // sort options
    this.productionOrdersDataSource.sort = this.productionOrdersSort;
    this.filteredProductionOrdersDataSource.sort = this.productionOrdersSort;
  }

  ngOnDestroy() {
    clearInterval(this.autoRefreshIntervalId);
    // clear production orders data and subscription
    if (this.productionOrdersSubscription) {
      this.productionOrdersSubscription.unsubscribe();
    }
    this.productionOrdersDataSource.disconnect();
    this.productionOrdersDataSource.data.length = 0;
  }

  setMachine() {
    this.errorGettingMachine = false;
    this.machine = this.authService.getMachine();
    if (!this.machine) {
      this.errorGettingMachine = true;
      this.machine = 'No IP address to show.';
      this.snackBarService.showError('Error setting machine. Please contact your supervisor. (EN) <br/> Error al configurar la direcci&oacute;n IP. Por favor contacte a su supervisor. (ES)');
    }
  }

  async refresh() {
    this.showLoading();

    await this.getProductionOrders();
    this.applyFilters();

    this.hideLoading();
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.setIsDefaultFullScreenMode();
  }

  setIsDefaultFullScreenMode() {
    this.fullScreenMode = this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  onProductionDateFilterChange() {
    if (!this.productionDateFilter.valid) {
      this.snackBarService.showWarning('Invalid production date.');
      this.productionDateFilter.setValue('');
    }
  }

  needToDisplayMaterialSupplier(e: ProductionOrder): boolean {
    const colors: string[] = ['Flour', 'Mist'];
    return e.materialSupplierId && colors.indexOf(e.color) !== -1;
  }

  async getColors() {
    try {
      this.colors = await this.productionCalendarService.getColors().toPromise();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    }
  }

  async onProductionLocationChange() {
    this.showLoading();
    await this.getProductionOrders();
    this.applyFilters();

    this.hideLoading();
  }

  async onRouterChange() {
    this.showLoading();
    await this.getProductionOrders();
    this.applyFilters();

    this.hideLoading();
  }

  filterByPONumber(): ProductionOrder[] {
    return this.filteredProductionOrdersDataSource.data.filter(u => u.productionOrderNumber && u.productionOrderNumber.toLowerCase().includes(this.poNumberFilter.value.toLowerCase()));
  }

  filterByProductionDate(): ProductionOrder[] {
    return this.filteredProductionOrdersDataSource.data.filter(u => u.productionDate && this.equals(this.productionDateFilter.value, u.productionDate));
  }

  filterByColors(): ProductionOrder[] {
    const colors: string[] = this.colorsFilter.value;
    const productionOrders: ProductionOrder[] = [];
    for (const color of colors) {
      const r = this.filteredProductionOrdersDataSource.data.filter(u => u.color && u.color.toLowerCase() === color.toLowerCase());
      productionOrders.push(...r);
    }
    return productionOrders;
  }

  equals(date1: string | Date, date2: string | Date): boolean {
    const a = new Date(date1);
    const b = new Date(date2);
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }

  getProductionOrdersDataSource(): MatTableDataSource<ProductionOrder> {
    return this.filtersApplied ? this.filteredProductionOrdersDataSource : this.productionOrdersDataSource;
  }

  applyFilters() {
    this.filteredProductionOrdersDataSource.data = this.productionOrdersDataSource.data;

    if (this.poNumberFilter.valid && this.poNumberFilter.value) {
      this.filteredProductionOrdersDataSource.data = this.filterByPONumber();
      this.filtersApplied = true;
    }
    if (this.productionDateFilter.valid && this.productionDateFilter.value) {
      this.filteredProductionOrdersDataSource.data = this.filterByProductionDate();
      this.filtersApplied = true;
    }
    if (this.colorsFilter.valid && this.colorsFilter.value && this.colorsFilter.value.length) {
      this.filteredProductionOrdersDataSource.data = this.filterByColors();
      this.filtersApplied = true;
    }
  }

  clearFilters() {
    this.poNumberFilter.setValue('');
    this.productionDateFilter.setValue('');
    this.colorsFilter.setValue('');
    this.filteredProductionOrdersDataSource.data = [];
    this.filtersApplied = false;
  }

  enableProductionManagerMode() {
    this.productionManagerModeEnabled = this.authService.isUserInRoles(ALLOWED_ROLES);
  }

  disableProductionOrderActions(p: ProductionOrder): boolean {
    const statuses: string[] = [`${ProductionStatus[ProductionStatus.ExportedToProduction]}`];
    return statuses.indexOf(p.status) !== -1;
  }

  openEditProductionOrder(id: number) {
    const productionOrder = this.productionOrdersDataSource.data.find(p => p.id === id);

    if (productionOrder) {
      const dialogRef = this.dialog.open(EditProductionOrderComponent, {
        width: '480px',
        data: {
          routerId: null,
          routers: [],
          productionOrder
        }
      });

      dialogRef.afterClosed().subscribe((productionOrder: ProductionOrder) => {
        if (productionOrder) {
          this.refresh();
          this.snackBarService.showSuccess('The production order was updated successfully.');
        }
      });
    }
  }

  async getProductionLocations() {
    try {
      this.productionLocations = await this.productionLocationsService.getActiveProductionLocations().toPromise();
      const defaultLocation = this.productionLocations.find(p => p.isDefault);
      this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting production locations.');
    }
  }

  async getProductionOrders() {
    this.clearDataSource();
    try {
      const productionOrders = await this.getProductionOrdersByLocation();
      this.productionOrdersDataSource.data = productionOrders;
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting production orders.');
    }
  }

  async getProductionOrdersByLocation(): Promise<ProductionOrder[]> {
    return await this.productionOrdersService.getTableSawProductionOrdersByLocation(this.productionLocationId).toPromise();
  }

  clearDataSource() {
    this.productionOrdersDataSource.data = [];
    this.filteredProductionOrdersDataSource.data = [];
  }

  hasProductionOrders() {
    return this.getProductionOrdersDataSource().data.length > 0;
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  async printPaperwork(id: number): Promise<void> {
    const machine = this.authService.getMachine();
    if (!machine) {
      this.snackBarService.showError('We can\'t print the paperwork without IP address. Please have your supervisor contact IT. (EN) <br/> No podemos imprimir la documentaci&oacute;n sin la direcci&oacute;n IP. P&iacute;dale a su supervisor que se comunique con TI. (ES)');
      return;
    }
    try {
      const productionOrder = await this.productionOrdersService.getProductionOrder(id).toPromise<ProductionOrder>();
      if (productionOrder.paperworkPrinted) {
        this.snackBarService.showWarning('The paperwork was printed already.');
        return;
      }
      const result = await this.productionOrdersService.printPaperwork(id, machine).toPromise<boolean>();
      if (result) {
        this.refresh();
        this.snackBarService.showSuccess('Paperwork was sent to print successfully.');
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error printing the paperwork. Please try again.');
    }
  }
}
